import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc, documentId, Firestore, getDocs, query, serverTimestamp,
  setDoc,
  updateDoc, where
} from '@angular/fire/firestore';
import { LcsEventTypes } from '@shared/enums';
import { Cluster, CollaborationCluster, MediaCluster, RecordingCluster } from '@shared/models';
import { getDiffBetweenObj } from '@shared/utils/common';
import { getClusterFunctionsPath, getCollaborationClusterPath, getMediaClusterPath, getRecordingClusterPath } from '@shared/utils/create-path';
import { lastValueFrom, Observable } from 'rxjs';
import { LcsEventsService } from '../lcs-events/lcs-events.service';

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  constructor(
    private firestore: Firestore,
    private lcsEventService: LcsEventsService,
    private http: HttpClient
  ) { }

  /**
   * Retrieves media clusters from Firestore.
   *
   * This method creates a collection reference using the Firestore instance and the configured media cluster path,
   * and then returns an observable stream of media cluster data. The data is cast to an array of MediaCluster objects,
   * with each object including an 'id' field.
   *
   * @returns An observable that emits an array of MediaCluster objects.
   */
  public getMediaClusters(): Observable<MediaCluster[]> {
    const colRef = <CollectionReference<MediaCluster>>(
      collection(this.firestore, getMediaClusterPath())
    );
    return collectionData<MediaCluster>(colRef, { idField: 'id' });
  }

  /**
   * Get collaboration clusters
   * @returns {Observable<CollaborationCluster[]>} Collaboration clusters
   */
  public getCollaborationClusters(): Observable<CollaborationCluster[]> {
    // Get the collection reference
    const colRef = <CollectionReference<CollaborationCluster>>(
      collection(this.firestore, getCollaborationClusterPath())
    );

    // Return the collection data
    return collectionData<CollaborationCluster>(colRef, { idField: 'id' });
  }

  /**
   * Get recording clusters
   * @returns {Observable<RecordingCluster[]>} Recording clusters
   */
  public getRecordingClusters(): Observable<RecordingCluster[]> {
    // Get the collection reference
    const colRef = <CollectionReference<RecordingCluster>>(
      collection(this.firestore, getRecordingClusterPath())
    );

    // Return the collection data
    return collectionData<RecordingCluster>(colRef, { idField: 'id' });
  }

  async getMediaClusterById(id: string): Promise<MediaCluster> {
    const colRef = <CollectionReference<MediaCluster>>(
      query(
        collection(this.firestore, getMediaClusterPath()),
        where(documentId(), '==', id)
      )
    );
    const querySnapshot = await getDocs<MediaCluster>(colRef);

    if (!querySnapshot.empty) {
      return { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
    }
    return null;
  }

  /**
   * Get a recording cluster by id
   * @param {string} id Recording cluster id
   * @returns {Promise<RecordingCluster>} Recording cluster
   */
  public async getRecordingClusterById(id: string): Promise<RecordingCluster> {
    // Get the collection reference
    const colRef = <CollectionReference<RecordingCluster>>(
      query(
        collection(this.firestore, getRecordingClusterPath()),
        where(documentId(), '==', id)
      )
    );

    // Get the query snapshot
    const querySnapshot = await getDocs<RecordingCluster>(colRef);

    // Return the cluster
    if (!querySnapshot.empty) {
      return { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
    }
    return null;
  }

  /**
   * Get a collaboration cluster by id
   * @param {string} id Collaboration cluster id
   * @returns {Promise<CollaborationCluster>} Collaboration cluster
   */
  public async getCollaborationClusterById(id: string): Promise<CollaborationCluster> {
    // Get the collection reference
    const colRef = <CollectionReference<CollaborationCluster>>(
      query(
        collection(this.firestore, getCollaborationClusterPath()),
        where(documentId(), '==', id)
      )
    );

    // Get the query snapshot
    const querySnapshot = await getDocs<CollaborationCluster>(colRef);

    // Return the cluster
    if (!querySnapshot.empty) {
      return { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
    }
    return null;
  }

  /**
   * Creates a media cluster in the Firestore database and logs the creation event.
   *
   * This asynchronous method creates a document for the provided media cluster using Firestore's
   * setDoc function. It records the creation time using serverTimestamp and includes the media
   * cluster's configuration details. After successfully creating the document, it triggers an event
   * to log the creation of the media cluster.
   *
   * @param cluster - An object representing the media cluster with the following properties:
   *   - id: The unique identifier for the media cluster.
   *   - name: The name of the media cluster.
   *   - domain: The domain associated with the media cluster.
   *   - ipAddress: The IP address of the media cluster.
   *   - port: The port number used by the media cluster.
   *   - active: A boolean indicating whether the media cluster is active.
   *   - vpnCredFile: The file path for the VPN credentials associated with the media cluster.
   *
   * @returns A promise that resolves after the media cluster has been created and the event has been logged.
   */
  public async createMediaCluster(cluster: MediaCluster): Promise<void> {
    // Get the document reference
    const docRef = doc(this.firestore, getMediaClusterPath(cluster.id));

    // Create
    await setDoc(docRef, {
      name: cluster.name,
      domain: cluster.domain,
      ipAddress: cluster.ipAddress,
      port: cluster.port,
      active: cluster.active,
      createdAt: serverTimestamp(),
      vpnCredFile: cluster.vpnCredFile,
    });

    // Create a new event
    return this.createLcsEvent(LcsEventTypes.MEDIA_CLUSTER_CREATION, '', cluster);
  }

  /**
   * Create a collaboration cluster
   * @param {CollaborationCluster} cluster Collaboration cluster
   */
  public async createCollaborationCluster(cluster: CollaborationCluster): Promise<void> {
    // Get the document reference
    const docRef = doc(this.firestore, getCollaborationClusterPath(cluster.id));

    // Default domain
    const defaultDomain = 'leucotron.io';

    // Default matrix prefix
    const defaultMatrixPrefix = 'chat';

    // Default jitsi prefix
    const defaultJitsiPrefix = 'conf';

    // Create the cluster
    await setDoc(docRef, {
      name: cluster.name,
      ip: cluster.ip,
      port: cluster.port,
      jitsiDomain: `${defaultJitsiPrefix}-${cluster.name}.${defaultDomain}`,
      matrixDomain: `${defaultMatrixPrefix}-${cluster.name}.${defaultDomain}`,
      createdAt: serverTimestamp(),
    });

    // Create a new event
    return this.createLcsEvent(LcsEventTypes.COLLABORATION_CLUSTER_CREATION, '', cluster);
  }

  /**
   * Creates a recording cluster in Firestore and logs the creation event.
   *
   * @param cluster - The recording cluster object containing the necessary data.
   * @returns A promise that resolves when the creation and event logging are complete.
   */
  public async createRecordingCluster(cluster: RecordingCluster): Promise<void> {
    // Get the document reference
    const docRef = doc(this.firestore, getRecordingClusterPath(cluster.id));

    // Create the cluster
    await setDoc(docRef, {
      name: cluster.name,
      ip: cluster.ip,
      port: cluster.port,
      createdAt: serverTimestamp(),
    });

    // Create a new event
    return this.createLcsEvent(LcsEventTypes.RECORDING_CLUSTER_CREATION, '', cluster);
  }

  /**
   * Updates an existing media cluster in Firestore and logs the update event.
   *
   * @param newCluster - The new media cluster object containing updated data.
   * @param oldCluster - (Optional) The previous media cluster object used to compute the difference for logging.
   * @returns A promise that resolves when the update and event creation have completed.
   *
   * @remarks
   * This method updates specific fields of the media cluster document in Firestore, including name, domain, ipAddress, port, active status, and vpnCredFile.
   * It also sets the updated timestamp using Firestore's serverTimestamp() function.
   * After updating, it logs the changes by creating a new event via the lcsEventService, using the difference between newCluster and oldCluster (excluding the 'id' field).
   */
  public async updateMediaCluster(newCluster: MediaCluster, oldCluster?: MediaCluster): Promise<any> {
    // Get the document reference
    const docRef = doc(this.firestore, getMediaClusterPath(newCluster.id));

    // Update the newCluster
    await updateDoc(docRef, {
      name: newCluster.name,
      domain: newCluster.domain,
      ipAddress: newCluster.ipAddress,
      port: newCluster.port,
      active: newCluster.active,
      updatedAt: serverTimestamp(),
      vpnCredFile: newCluster.vpnCredFile,
    });

    // Create a new event
    return this.lcsEventService.create(LcsEventTypes.MEDIA_CLUSTER_UPDATE, '', '', getDiffBetweenObj(newCluster, oldCluster, ['id']));
  }

  /**
   * Deletes the specified media cluster from Firestore and creates a corresponding deletion event.
   *
   * This method removes the document associated with the provided media cluster id from Firestore.
   * After successfully deleting the cluster, it triggers a local event to signal that a media cluster
   * has been deleted using the event type MEDIA_CLUSTER_DELETION.
   *
   * @param cluster - The media cluster object to be deleted. It must contain a valid id used to locate the corresponding document.
   * @returns A Promise that resolves after the deletion operation and event creation are completed.
   */
  public async deleteMediaCluster(cluster: MediaCluster): Promise<void> {
    // Get the document reference
    const docRef = doc(this.firestore, getMediaClusterPath(cluster.id));

    // Delete the cluster
    await deleteDoc(docRef);

    // Create a new event
    return this.createLcsEvent(LcsEventTypes.MEDIA_CLUSTER_DELETION, '', cluster);
  }

  /**
   * Delete a collaboration cluster
   * @param {CollaborationCluster} cluster Collaboration cluster
   */
  public async deleteCollaborationCluster(cluster: CollaborationCluster): Promise<void> {
    // Get the document reference
    const docRef = doc(this.firestore, getCollaborationClusterPath(cluster.id));

    // Delete the cluster
    await deleteDoc(docRef);

    // Create a new event
    return this.createLcsEvent(LcsEventTypes.COLLABORATION_CLUSTER_DELETION, '', cluster);
  }

  /**
   * Delete a recording cluster
   * @param {RecordingCluster} cluster Recording cluster
   */
  public async deleteRecordingCluster(cluster: RecordingCluster): Promise<void> {
    // Get the document reference
    const docRef = doc(this.firestore, getRecordingClusterPath(cluster.id));

    // Delete the cluster
    await deleteDoc(docRef);

    // Create a new event
    return this.createLcsEvent(LcsEventTypes.RECORDING_CLUSTER_DELETION, '', cluster);
  }

  /**
   * Check if the cluster is a media cluster
   * @param {Cluster} cluster Cluster
   * @returns {boolean} True if the cluster is a media cluster
   */
  public isMediaCluster(cluster: Cluster): boolean {
    return 'domain' in cluster;
  }

  /**
   * Check if the cluster is a collaboration cluster
   * @param {Cluster} cluster Cluster
   * @returns {boolean} True if the cluster is a collaboration cluster
   */
  public isCollaborationCluster(cluster: Cluster): boolean {
    return 'jitsiDomain' in cluster;
  }

  /**
   * Create lcs event
   * @param {LcsEventTypes} type Event type
   * @param {string} description Event description
   * @param {Cluster} cluster Cluster
   * @returns {Promise<void>} Promise
   */
  public async createLcsEvent(type: LcsEventTypes, description: string, cluster: Cluster): Promise<void> {
    try {
      // Check if the cluster is a media cluster
      let data: any = null;

      // Create the value
      let value: any = {
        id: cluster.id,
        name: cluster.name,
        port: cluster.port,
      };

      switch (true) {

        // Media cluster
        case this.isMediaCluster(cluster):
          // Pass the cluster as MediaCluster
          data = cluster as MediaCluster;

          // Add new properties to the value
          value = {
            ...value,
            ipAddress: data.ipAddress,
            domain: data.domain,
          };
          break;

        // Collaboration cluster
        case this.isCollaborationCluster(cluster):
          // Pass the cluster as CollaborationCluster
          data = cluster as CollaborationCluster;

          // Add new properties to the value
          value = {
            ...value,
            ipAddress: data.ip,
            jitsiDomain: data.jitsiDomain,
            matrixDomain: data.matrixDomain,
          };
          break;

        // Recording cluster
        default:
          // Pass the cluster as RecordingCluster
          data = cluster as RecordingCluster;

          // Add new properties to the value
          value = {
            ...value,
            ipAddress: data.ip,
          };
          break;
      }

      // Create the event
      await this.lcsEventService.create(type, description, '', value);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Sync collaboration cluster
   * @param {Cluster} cluster Collaboration cluster
   * @returns {Promise<void>} Promise
   */
  public async syncCluster(cluster: Cluster, type: string): Promise<void> {
    // Get the path to the user import endpoint
    const url = getClusterFunctionsPath() + '/sync-cluster';

    // Create body
    const body = {
      type,
      clusterId: cluster.id,
    };

    // Send the request to the endpoint
    await lastValueFrom(this.http.post<void>(url, body));

    // Create a new event
    return this.createLcsEvent(LcsEventTypes.COLLABORATION_CLUSTER_SYNC, '', cluster);
  }
}
