import { Injectable } from '@angular/core';
import { PREFIX_STORAGE } from '@shared/constants';

@Injectable({
  providedIn: 'root'
})
export class NavigatorStorageService {
  constructor() { }

  /**
   * Get the company list mode
   * @returns {string} variable that contains company list mode
   */
  public getCompanyListMode(): string {
    return localStorage.getItem(`${PREFIX_STORAGE}:companyListMode`);
  }

  /**
   * Set the company list mode
   */
  public setCompanyListMode(config: string): void {
    localStorage.setItem(`${PREFIX_STORAGE}:companyListMode`, config);
  }
}
