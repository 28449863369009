import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TICKETS_STATUS, TICKETS_TYPE } from '@shared/constants';
import { CallsSummary, Cdr, CdrFilterParams, SimultCalls, simultCallsParams } from '@shared/models';
import { DateFormatPipe, NumberToMinutesPipe } from '@shared/pipes';
import { formParams } from '@shared/utils';
import { getCDRPath } from '@shared/utils/create-path';
import { Observable } from 'rxjs';
import { CsvService } from '../csv/csv.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CdrService {

  constructor(
    private http: HttpClient,
    private datePipe: DateFormatPipe,
    private timePipe: NumberToMinutesPipe,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private csvService: CsvService,
  ) { }

  /**
   * Export tickets in a CSV file
   * @param {Cdr[]} tickets All tickets that will be exported
   * @param {string} companyId Company ID | Tenant ID
   */
  public exporCSV(tickets: Cdr[], companyId: string) {
    try {
      // Define file name
      const fileName = `${companyId}.csv`;

      // Define the columns
      const columns: string[] = [
        `${this.translate.instant('date')}/${this.translate.instant('hour')}`,
        this.translate.instant('protocol'),
        this.translate.instant('source'),
        this.translate.instant('destiny'),
        this.translate.instant('type'),
        this.translate.instant('status'),
        this.translate.instant('duration'),
      ];

      // Define the attributes that will be saved in the CSV
      const keys: string[] = [
        'start',
        'uniqueid',
        'src',
        'dst',
        'dcontext',
        'disposition',
        'billsec',
      ];

      // Parse the tickets data
      const data: Cdr[] = tickets.map(ticket => {
        return {
          ...ticket,
          start: this.datePipe.transform(ticket.start),
          dcontext: this.translate.instant(this.utilsService.parseAttr(ticket.dcontext, TICKETS_TYPE)),
          disposition: this.translate.instant(this.utilsService.parseAttr(ticket.disposition, TICKETS_STATUS)),
          billsec: this.timePipe.transform(+ticket.billsec),
        }
      })

      // Generate CSV
      this.csvService.exportCSV(columns, keys, data, fileName);

    } catch (error) {
      throw new Error('Fail on export csv file');
    }
  }



  /**
   * Get all tickets
   * @returns {Cdr[]} All tickets
   */
  public fetchAll(filter: CdrFilterParams): Observable<HttpResponse<Cdr[]>> {

    // Get path
    const path = getCDRPath() + '/calls';

    // Return the tickets
    return this.http.get<Cdr[]>(path, {
      observe: 'response',
      params: formParams(filter),
    });
  }

  /**
   * Get all tickets
   * @returns {Cdr[]} All tickets
   */
  public getCallSummary(filter: CdrFilterParams): Observable<CallsSummary> {

    // Get path
    const path = getCDRPath() + '/calls/summary';

    // Return the tickets
    return this.http.get<CallsSummary>(path, {
      params: formParams(filter)
    });
  }

  /**
   * Get current simult calls count
   * @param filter Filter query
   * @returns All simult calls in each cluster
   */
  public getCurrentSimultCallsCount(filter: simultCallsParams): Observable<SimultCalls[]> {
    // Get path
    const path = getCDRPath() + '/calls/simultaneos/most-recent';

    // Return the simult calls
    return this.http.get<SimultCalls[]>(path, {
      params: formParams(filter)
    });
  }

  public getSimultCalls(filter: simultCallsParams): Observable<SimultCalls[]> {
    // Get path
    const path = getCDRPath() + '/calls/simultaneos';

    // Return the simult calls
    return this.http.get<SimultCalls[]>(path, {
      params: formParams(filter)
    });
  }
}
