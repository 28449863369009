import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AdvancedBillingFilter, Billing, BillingDataSource, BillingSearchResult } from '@shared/models';
import { DateFormatPipe } from '@shared/pipes';
import { formParams } from '@shared/utils';
import { first, lastValueFrom, Observable } from 'rxjs';
import { CsvService } from '../csv/csv.service';
import { BillingStatus } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private url = environment.baseURL + '/billing'

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private customDatePipe: DateFormatPipe,
    private csvService: CsvService,
  ) { }

  /**
   * Save billing on DB
   * @param {Billing} data Billing data
   */
  public insert(data: Billing): void {
    lastValueFrom(this.http.post(this.url, data).pipe(first()));
  }

  /**
   * List the billings
   * @param {AdvancedBillingFilter} filter Advanced Billing Filter data
   */
  public list(filter: AdvancedBillingFilter): Observable<HttpResponse<BillingSearchResult>> {
    const path = this.url + '/list';

    // Return the tickets
    return this.http.get<BillingSearchResult>(path, {
      observe: 'response',
      params: formParams(filter),
    });
  }

  /**
   * Export billings in a CSV file
   * @param {Billing[]} billings All billings that will be exported
   */
  public exporCSV(billings: BillingDataSource[]) {
    try {
      // Define file name
      const fileName = `${this.translate.instant('billing')}.csv`;

      // Define the columns
      const columns: string[] = [
        `${this.translate.instant('date')}/${this.translate.instant('hour')}`,
        `${this.translate.instant('user.singular')}/${this.translate.instant('email')}`,
        this.translate.instant('partner.singular'),
        this.translate.instant('company.singular'),
        this.translate.instant('simultCalls'),
        this.translate.instant('usersLimit'),
        this.translate.instant('recordingRetentionTime'),
        this.translate.instant('trialTime'),
        this.translate.instant('status'),
        'PABX',
        this.translate.instant('billingCycle'),
        this.translate.instant('modality'),
        this.translate.instant('partnerType')
      ];

      // Define the attributes that will be saved in the CSV
      const keys: string[] = [
        'date',
        'user',
        'partner',
        'company',
        'simultCalls',
        'userLimit',
        'recordingRetentionTime',
        'trialTime',
        'status',
        'pbx',
        'billingCycle',
        'planType',
        'partnerType'
      ];

      // Parse the billings data
      const data: BillingDataSource[] = this.handleExportFilter([...billings])
        .map(billing => {
          return {
            ...billing,
            date: this.customDatePipe.transform(billing.date),
            trialTime: this.customDatePipe.transform(billing.trialTime),
            status: this.translate.instant('billing.' + billing.status),
            pbx: this.translate.instant('pabx.' + billing.pbx),
            billingCycle: billing.billingCycle ? this.translate.instant("payment." + billing.billingCycle) : '',
            planType: billing.planType ? this.translate.instant(billing.planType) : '',
            partnerType: billing.partnerType ? this.translate.instant(billing.partnerType) : ''
          }
        })

      // Generate CSV
      this.csvService.exportCSV(columns, keys, data, fileName);

    } catch (error) {
      console.error(error)
      throw new Error('Fail on export csv file');
    }
  }

  /**
   * Handle the billing export filter
   * @param {BillingDataSource[]} billings All billings that will be exported
   * @returns {BillingDataSource[]} The filtered billings
   */
  public handleExportFilter(billings: BillingDataSource[]): BillingDataSource[] {
    return billings
      .filter(billing => billing.status !== BillingStatus.DEACTIVATION); // Exclude the deactivation billings
  }
}
