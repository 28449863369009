import { FormControl } from "@angular/forms";

export interface MediaCluster {
  id: string;
  name: string;
  domain: string;
  ipAddress: string;
  port: string;
  active: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  vpnPort?: string;
  vpnCredFile?: string | File;
  error?: any;
}

export interface PortainerConfig {
  url: string;
  swarmId: string;
}

export interface CloudflareDNSRecordData {
  id: string;
  name: string;
}

export type CollaborationClusterState = 'init' | 'portainer' | 'matrix' | 'jitsi' | 'done';

export interface CollaborationCluster {
  id?: string;
  name: string;
  jitsiDomain: string;
  matrixDomain: string;
  password: string;
  active?: boolean;
  ip: string;
  port: number;
  createdAt?: Date;
  matrixToken: string;
  updatedAt?: Date;
  portainer?: PortainerConfig;
  cloudflare?: {
    dns: CloudflareDNSRecordData[];
  },
  state?: CollaborationClusterState;
  error?: string;
}
export interface RecordingCluster {
  id?: string;
  name: string;
  password: string;
  active?: boolean;
  ip: string;
  port: number;
  createdAt?: Date;
  updatedAt?: Date;
  portainer?: PortainerConfig;
  cloudflare?: {
    dns: CloudflareDNSRecordData[];
  },
  state?: CollaborationClusterState;
  error?: string;
}

export type Cluster = MediaCluster | CollaborationCluster | RecordingCluster;

// Export collaboration cluster form controls
export interface CollaborationClusterForm {
  name: FormControl<string>;
  ip: FormControl<string>;
  port: FormControl<number>;
};
