import { MediaCluster, Company, CompanyPlan, PlanTypes } from "@shared/models";
import { KJUR } from 'jsrsasign';

/**
 * Get Company Status
 * @param {Company} company Company data
 * @returns {CompanyPlan} Company status
 */
export const getCompanyPlanType = (company: Company): CompanyPlan => {
  switch (company.planType) {
    case PlanTypes.TRIAL:
      return {
        text: 'Trial',
        class: 'trial'
      };
    case PlanTypes.SHOWCASE:
      return {
        text: 'showcase',
        class: 'trial'
      };
    case PlanTypes.CONTRACT:
      return {
        text: 'contract',
        class: 'trial'
      };
    default:
      return {
        text: 'client',
        class: 'client'
      };
  }
}

/**
 * Get company postfix
 * Example: Teste-ntt4y => ntt4y
 * @param {string} companyId Company id
 * @returns {string} Company postfix
 */
export const getCompanyPostfix = (companyId: string): string => {
  const idSplitted = companyId.split('-');
  return idSplitted[idSplitted.length - 1];
}


/**
 * Create External JWT Token
 * @param {string} data Payload
 * @param {number} minutes Expiration time in minutes
 * @returns {string} JWT Token
 */
export function createJWTToken<T>(data: T, minutes: number = 30): string {
  // Constants
  const ALG = 'HS256';
  const TYPE = 'JWT';
  const HEADER = { alg: ALG, typ: TYPE };
  const SECRET = 'fsexvmkleeuawdwl'

  // Get initial date
  const current = KJUR.jws.IntDate.get('now');

  // Expiration (minutes)
  const expiration = current + minutes * 60;

  // Payload
  const payload = {
    iat: current,
    nbf: current,
    exp: expiration,
    data,
  };

  // Sign JWT
  return KJUR.jws.JWS.sign(
    ALG,
    JSON.stringify(HEADER),
    JSON.stringify(payload),
    SECRET
  );
}

/**
 * Create cluster base data
 * @param {Company} company Company data
 * @param {Cluster} cluster Cluster data
 */
export const createClusterBaseData = (company: Company, cluster: MediaCluster) => {
  return {
    clusterid: cluster.id,
    domain: cluster.domain,
    domainId: company.id,
    simultcalls: company.simultcalls,
    recordingEnabled: company.recordingRetentionTime != 0,
    endpoints: [],
    trunks: [],
  }
}
