import { Injectable } from '@angular/core';
import {
  uploadBytes,
  ref,
  Storage,
  getDownloadURL,
  deleteObject,
  getMetadata,
} from '@angular/fire/storage';
import { Company, MediaCluster } from '@shared/models';
import * as JSZip from 'jszip';
import { UtilsService } from '../utils/utils.service';
import { HttpClient } from '@angular/common/http';
import { LoadingSpinnerService } from '../loading-spinner/loading-spinner.service';
import { first, lastValueFrom } from 'rxjs';
import { VPN_FILE_NAME } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    private storage: Storage,
    private utilsService: UtilsService,
    private httpClient: HttpClient,
    private spinner: LoadingSpinnerService,
  ) { }

  /**
   * Format recordings path
   * @param path Current path
   * @returns Formetted path with /recordings/
   */
  private formattedRecordingsPath(path: string): string {
    // Return if already includes
    if (path.includes('/recordings/')) return path;
    const companyId = path.substring(0, path.indexOf('/'));
    const filename = path.substring(path.indexOf('/') + 1);
    return companyId + '/recordings/' + filename;
  }

  async uploadFile(file: File, company: Company): Promise<string> {
    const fileExtension: string = file.type.split('/')[1];
    const filePath = `${company.id}/${company.id}.${fileExtension}`;
    const fileRef = ref(this.storage, filePath);
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  }

  /**
   * Upload VPN file to firebase storage
   * @param {File} file File to upload
   * @param {MediaCluster} cluster Cluster to upload
   * @returns {string} URL to file
   */
  public async uploadVPNFile(file: File, cluster: MediaCluster): Promise<string> {
    // Format path
    const filePath = `Clusters/${cluster.id}/${VPN_FILE_NAME}`;

    // Form file ref
    const fileRef = ref(this.storage, filePath);

    // Upload file
    await uploadBytes(fileRef, file);

    // Return download URL
    return getDownloadURL(fileRef);
  }

  /**
   * Remove img from firebase storage
   * @param {string} filePath URL to img
   */
  public async deleteFile(filePath: string): Promise<void> {
    const fileRef = ref(this.storage, filePath);
    await deleteObject(fileRef);
  }

  public getDownloadURL(path: string): Promise<string> {
    path = this.formattedRecordingsPath(path);
    const storageRef = ref(this.storage, path);
    return getDownloadURL(storageRef);
  }

  /**
   * Download file to computer
   * @param {string} path Path to the file
   */
  public async downloadFile(path: string) {
    try {
      // Download file
      await this.getDownloadURL(path)
        .then(async (url) => {
          await this.utilsService.downloadFile(url, path)
        })
    } catch (error) {
      throw new Error('Fails on download file')
    }
  }

  /**
   * Download zip with the recordings
   * @param {string[]} files Paths file
   * @param {string} zipName Zip name
   * @returns {number} Count of all tickets that will be zipped
   */
  public async downloadZippedFiles(files: string[], zipName: string): Promise<number> {

    // Show spinner
    this.spinner.show()

    // Declare jsZip obj
    const zipFile: JSZip = new JSZip();

    // Start counter
    let count = 0;

    for (const file of files) {

      try {
        // Get URL to download
        const url = await this.getDownloadURL(file);

        // Get blob
        const blob = await lastValueFrom(this.httpClient.get(url, { responseType: 'blob' }).pipe(first()))

        // Added file to zip
        zipFile.file(file, blob, { binary: true });

        // Increment count
        count++;

      } catch (error) {
        // Logger
      }
    };

    // Verify if find any recordings
    if (count) {
      // Generate zip content
      const zipContent = await zipFile.generateAsync({ type: 'blob' });

      // Download zip content
      this.utilsService.downloadFile(zipContent, zipName + '.zip');
    }

    // Hide spinner
    this.spinner.hide();

    return count;
  }

  /**
   * Get cluster certificate info from firebase storage
   * @returns {Promise<any>} Data (metadata, name...) from the file
   */
  public async getClusterCertificates(): Promise<any> {
    // Path to the file
    const path = 'certificates/crt.tar.gz';

    // Get metadata
    return getMetadata(ref(this.storage, path))
  }

  /**
   * Upload cluster certificate to firebase storage
   * @param {File} file File to upload
   * @param {any} metadata Metadata
   * @returns {Promise<void>} URL to file
   */
  public async uploadClusterCertificate(file: File, metadata: any): Promise<void> {
    // Path to the file
    const path = 'certificates/crt.tar.gz';

    // Form file ref
    const fileRef = ref(this.storage, path);

    // Upload file
    await uploadBytes(fileRef, file, { customMetadata: metadata });
  }
}
