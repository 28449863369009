import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  documentId,
  Firestore,
  getDocs,
  query,
  QueryConstraint,
  where,
} from '@angular/fire/firestore';
import { Company, User } from '@shared/models';
import { getUserFirestorePath, getUserFunctionsPath, getUserPath } from '@shared/utils/create-path';
import { doc, updateDoc } from 'firebase/firestore';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LcsEventsService } from '../lcs-events/lcs-events.service';
import { LcsEventTypes } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private firestore: Firestore,
    private http: HttpClient,
    private lcsEventsService: LcsEventsService,
  ) { }

  /**
   * Get all users from a specific company
   *
   * @param id Company identifier
   * @returns
   */
  public fetchAll(id: string): Observable<User[]> {
    const col = <CollectionReference<User>>(
      collection(this.firestore, getUserFirestorePath(id))
    );

    return collectionData<User>(col, { idField: 'id' });
  }

  /**
   * Import a list of users to Identity Platform and Firestore
   *
   * @param company Tenant data
   * @param users A list of users to import
   * @returns
   */
  importUsers(
    company: Company,
    users: User[]
  ): Promise<User[]> {
    // Get the path to the user import endpoint
    const url = getUserFunctionsPath(company.id) + '/import';

    // Create body
    const body = {
      tenantId: company.id,
      users,
      lcsEvent: this.lcsEventsService
        .baseLcsEvent(LcsEventTypes.USER_IMPORT, '', company.id)
    };

    // Send the request to the endpoint
    return lastValueFrom(this.http.post<User[]>(url, body));
  }

  /**
   * Create user on Identity Platform and Firestore
   *
   * @param company Tenant data
   * @param user User to be created
   * @returns
   */
  create(company: Company, user: User): Promise<{ createdUser: User; password: string }> {
    // Get the path to the user creation endpoint
    const url = getUserFunctionsPath(company.id);

    // Create event
    const body = {
      clusterId: company.clusterId,
      tenantId: company.id,
      user: {
        ...user,
        pbxId: user.pbxId ?? ''
      },
      lcsEvent: this.lcsEventsService.baseLcsEvent(LcsEventTypes.USER_CREATION, '', company.id)
    };

    // Send the request to the endpoint
    return lastValueFrom(this.http.post<any>(url, body));
  }

  /**
   * Update user on Identity Platform and Firestore
   *
   * @param company Tenant data
   * @param userId User id to be updated
   * @param newUserData New user data
   * @returns
   */
  update(
    company: Company,
    userId: string,
    newUserData: User,
  ): Promise<User> {
    // Get the path to the user update endpoint
    const url = getUserFunctionsPath(company.id, userId);

    // Create event
    const body = {
      tenantId: company.id,
      user: {
        ...newUserData,
        pbxId: newUserData.pbxId ?? ''
      },
      lcsEvent: this.lcsEventsService.baseLcsEvent(LcsEventTypes.USER_UPDATE, '', company.id)
    };

    // Send the request to the endpoint
    return lastValueFrom(this.http.put<User>(url, body));
  }

  /**
  * Update tags in the user
  * @param {Company} company The company that is the owner of the user
  * @param {string} userId New Data
  * @param {string[]} tags New Data
  */
  public updateTags(company: Company, userId: string, tags: string[]): Promise<void> {
    const docRef = doc(this.firestore, getUserPath(company.id, userId));
    return updateDoc(docRef, { groups: tags });
  }

  /**
   * Delete user from Identity Platform, Firestore and Cluster
   *
   * @param cluster Voice server data
   * @param company Tenant Data
   * @param userId User id to be deleted
   * @returns
   */
  delete(company: Company, users: User[]): Observable<void> {
    // Get the path to the user deletion endpoint
    const cloudFunctionURL = getUserFunctionsPath(company.id);

    // Delete from Identity Platform and Firestore
    return this.http
      .delete<void>(cloudFunctionURL, {
        body: {
          clusterId: company.clusterId,
          tenantId: company.id,
          users,
          lcsEvent: this.lcsEventsService.baseLcsEvent(LcsEventTypes.USER_DELETION, '', company.id)
        },
      })
      .pipe(catchError(this.handleError));
  }

  async getUserByEmail(email: string, company: Company): Promise<User> {
    const path = getUserFirestorePath(company.id);

    const queryConstraint: QueryConstraint[] = [where('email', '==', email)];
    const querySnapshot = await getDocs(
      query(
        collection(this.firestore, path) as CollectionReference<User>,
        ...queryConstraint
      )
    );
    return querySnapshot.docs[0].data() as User;
  }

  async getUserByExtension(extension: string, company: Company): Promise<User> {
    const path = getUserFirestorePath(company.id);

    const queryConstraint: QueryConstraint[] = [where('extension', '==', extension.toString())];
    const querySnapshot = await getDocs(
      query(
        collection(this.firestore, path) as CollectionReference<User>,
        ...queryConstraint
      )
    );
    if (querySnapshot.docs[0])
      return { ...querySnapshot.docs[0]?.data(), id: querySnapshot.docs[0]?.id }
    return querySnapshot.docs[0]?.data()
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }


  async getById(id: string, companyId: string): Promise<User> {
    const colRef = <CollectionReference<User>>(
      query(
        collection(this.firestore, getUserPath(companyId)),
        where(documentId(), '==', id)
      )
    );
    const querySnapshot = await getDocs<User>(colRef);

    if (!querySnapshot.empty) {
      return { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
    }
    return null;
  }
}
