import { BillingCycle, PABX_INTEGRATION_OPTIONS } from '@shared/enums';
import { PBX, User, LoginOptions } from '@shared/models';

export interface Company {
  id: string;
  name: string;
  clusterId: string;
  partner?: string;
  email?: string;
  imgUrl?: string;
  usersCount?: string;
  active?: boolean;
  msIntegration?: boolean;
  loginOptions?: LoginOptions[];
  needToSync?: boolean;
  users?: User[];
  media?: PBX[];
  createdAt?: any;
  updatedAt?: any;
  simultcalls: number;
  maxUsersLimit: number;
  recordingEnabled: boolean;
  collaborationEnabled: boolean;
  language: string;
  timezoneName?: string;
  cnpj: string;
  hasMultiTalkIntegration: boolean;
  recordingRetentionTime: number;
  multiTalkCompanyId?: number;
  pbxIntegration?: PABX_INTEGRATION_OPTIONS;
  matrixRoomId?: string;
  billingCycle?: BillingCycle;
  trialTime?: any;
  trialTimeEnabled?: boolean;
  planType?: PlanTypes;
  messageModificationMaxTime?: MessageModificationMaxTimeOptions;
}

export interface CompanyPlan {
  text: string,
  class: string
}

export enum PlanTypes {
  TRIAL = "trial",
  SHOWCASE = "showcase",
  CONTRACT = "contract",
  CLIENT = "client"
}

export enum MessageModificationMaxTimeOptions {
  Unlimited = 'unlimited',
  Never = 'never',
  OneMinute = 'one_minute',
  FiveMinutes = 'five_minutes',
  TenMinutes = 'ten_minutes',
  FifteenMinutes = 'fifteen_minutes'
}