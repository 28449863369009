import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfCnpj'
})
export class CpfCnpjPipe implements PipeTransform {
  transform(value: string | number, onlyCnpj = false): string {
    if (!value) return null;

    const onlyNumbers = `${value}`.replace(/[^0-9]/g, '');
    let result = onlyNumbers;

    if (onlyNumbers.length == 11 && !onlyCnpj)
      result = onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");

    if (onlyNumbers.length == 14)
      result = onlyNumbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");

    return result;

  }
}
