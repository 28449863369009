import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Company,
  User,
  MultiTalkCompany,
  MultiTalkCompanyCreateResult,
  MultiTalkUser,
  MultiTalkResult,
  MultiTalkUserCreateResult,
  MultiTalkUserUpdateResult,
  Dialplan,
} from '@shared/models';
import { getMultiTalkCompanyPath, getMultiTalkDialPlanPath, getMultiTalkUserPath } from '@shared/utils';
import { first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultiTalkService {
  private defaultTheme = 0;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Form company data
   * @param {Company} company Company data
   * @returns Necessary multi talk data
   */
  private formCompanyBody(company: Company) {
    return {
      companyId: company.id,
      recording: company.recordingRetentionTime != 0,
      simultcalls: company.simultcalls,
      maxUsersLimit: company.maxUsersLimit,
      cnpj: company.cnpj
    }
  }

  /**
   * Form user data
   * @param {string} companyId Company id
   * @param {User} user user data
   * @returns Necessary multi talk data
   */
  private formUserBody(companyId: string, user: User) {
    return {
      companyId,
      name: user.displayName,
      number: user.extension,
      password: user.passwordExten,
      areaCode: user.areaCode,
      dialplanId: user.dialplanId,
    }
  }

  // ---------------- START - COMPANY ----------------
  /**
   * Make login on Multi Talk
   * @param {string} companyId Company Id
   * @param {string} password Password
   * @param {number} theme Theme
   * @returns {Promise<{ link: string }>} Link to Multi Talk
   */
  public makeLogin(
    companyId: string,
    password: string,
    theme: number = this.defaultTheme
  ): Promise<{ link: string }> {
    // Form the path
    const path = getMultiTalkCompanyPath() + "/login";

    // Form the body
    const body = { user: companyId, password, theme }

    // Make the request
    return lastValueFrom(this.http.post<{ link: string }>(path, body).pipe(first()));
  }

  /**
   * Get Multi Talk companies
   * @param {number} multiTalkCompanyId Multi Talk Company Id
   * @returns {Promise<MultiTalkResult<MultiTalkCompany>>}
   */
  public listCompanies(
    multiTalkCompanyId: number
  ): Promise<MultiTalkResult<MultiTalkCompany>> {
    const path = getMultiTalkCompanyPath(multiTalkCompanyId);
    return lastValueFrom(this.http.get<MultiTalkResult<MultiTalkCompany>>(path).pipe(first()));
  }

  /**
   * Create company on multi talk
   * @param {Company} company Company data
   * @returns {Promise<MultiTalkCompanyCreateResult>} Multi Talk Company Id
   */
  public createCompany(company: Company): Promise<MultiTalkCompanyCreateResult> {
    const path = getMultiTalkCompanyPath();
    return lastValueFrom(this.http.post<MultiTalkCompanyCreateResult>(path, this.formCompanyBody(company)).pipe(first()));
  }

  /**
   * Update company on multi talk
   * @param {number} multiTalkCompanyId Multi talk company id
   * @param {Company} newCompanyData Company data
   */
  public updateCompany(
    multiTalkCompanyId: number,
    newCompanyData: Company
  ): Promise<void> {
    const path = getMultiTalkCompanyPath(multiTalkCompanyId);
    return lastValueFrom(this.http.patch<void>(path, this.formCompanyBody(newCompanyData)).pipe(first()));
  }

  /**
   * Delete a company on Multi Talk
   * @param {number} multiTalkCompanyId Multi Talk Company Id
   */
  public deleteCompany(multiTalkCompanyId: number): Promise<void> {
    const path = getMultiTalkCompanyPath(multiTalkCompanyId);
    return lastValueFrom(this.http.delete<void>(path).pipe(first()));
  }
  // ---------------- END - COMPANY ----------------

  // ---------------- START - USER ----------------
  /**
   * Get Multi Talk users
   * @param {number} multiTalkCompanyId Multi talk company id
   * @param {number} multiTalkUserId Multi talk user id
   * @returns {Promise<MultiTalkResult<MultiTalkUser>>}
   */
  public listUsers(
    multiTalkCompanyId: number,
    multiTalkUserId: number
  ): Promise<MultiTalkResult<MultiTalkUser>> {
    const path = getMultiTalkUserPath(multiTalkCompanyId, multiTalkUserId);
    return lastValueFrom(this.http.get<MultiTalkResult<MultiTalkUser>>(path).pipe(first()));
  }

  /**
   * Create user on multi talk
   * @param {number} multiTalkCompanyId Multi talk company id
   * @param {string} companyId Firestore company id
   * @param {User} user User data
   * @returns {Promise<MultiTalkUserCreateResult>} Multi Talk user result data
   */
  public createUser(
    multiTalkCompanyId: number,
    companyId: string,
    user: User
  ): Promise<MultiTalkUserCreateResult> {
    const path = getMultiTalkUserPath(multiTalkCompanyId);
    return lastValueFrom(this.http.post<MultiTalkUserCreateResult>(path, this.formUserBody(companyId, user)).pipe(first()));
  }

  /**
   * Update company on multi talk
   * @param {number} multiTalkCompanyId Multi talk company id
   * @param {number} multiTalkUserId Multi talk user id
   * @param {Company} newUserData Company data
   */
  public updateUser(
    multiTalkCompanyId: number,
    multiTalkUserId: number,
    companyId: string,
    newUserData: User
  ): Promise<MultiTalkUserUpdateResult> {
    const path = getMultiTalkUserPath(multiTalkCompanyId, multiTalkUserId);
    return lastValueFrom(this.http.patch<MultiTalkUserUpdateResult>(path, this.formUserBody(companyId, newUserData)).pipe(first()));
  }

  /**
   * Delete a user on Multi Talk
   * @param {number} multiTalkCompanyId Multi talk company id
   * @param {number} multiTalkUserId Multi talk user id
   */
  public deleteUser(multiTalkCompanyId: number, multiTalkUserId: number): Promise<void> {
    const path = getMultiTalkUserPath(multiTalkCompanyId, multiTalkUserId);
    return lastValueFrom(this.http.delete<void>(path).pipe(first()));
  }
  // ---------------- END - USER ----------------

  /**
   * Fetch all company Dialplans
   * @param multiTalkCompanyId Company id
   * @returns List of Dialplans
   */
  public fetchAllDialPlans(multiTalkCompanyId: number): Promise<MultiTalkResult<Dialplan>> {
    const path = getMultiTalkDialPlanPath(multiTalkCompanyId);
    return lastValueFrom(this.http.get<MultiTalkResult<Dialplan>>(path).pipe(first()));
  }
}
