export enum LOGIN_TYPE {
  PASSWORD = 'm.login.password',
  TOKEN = 'm.login.token'
}

export enum LOGIN_IDENTIFIER_TYPE {
  USER = 'm.id.user',
  THIRD_PARTY = 'm.id.thirdparty',
  PHONE = 'm.id.phone'
}
