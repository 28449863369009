export interface LcsEvent {
  uuid?: string;
  date?: Date;
  userId: string,
  userEmail: string,
  companyId: string,
  device: string,
  description: string,
  type: string,
  value: any,
}

export interface LcsEventsFilter {
  page?: number;
  limit?: number;
  initialDate?: string;
  finalDate?: string;
  userId?: string,
  companyId?: string,
  device?: string,
  description?: string,
  type?: any,
}
