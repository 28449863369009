import { PABX_SKYVOICE, PBX, PbxModelEnum, User } from "@shared/models";

/**
  * Form Users Endpoint Body
  * @param {User} user User Data
  * @param {Partial<PBX>} pbx PBX Data
  * @returns Endpoint body
  */
export const formUsersEndpointBody = (user: User, pbx: Partial<PBX>) => {
  return {
    extension: user.extension,
    passwordExten: user.passwordExten,
    uuid: user.id,
    email: user.email,
    authuser: user.authuser ?? user.multiTalkAuthorizationUser,
    displayName: user.displayName,
    callbypbx: user.pbxRecEnabled ?? false,
    domainPbx: getDomainPbx(user, pbx),
    portSipPbx: pbx?.portSipPbx ?? '',
    dtmfMode: pbx?.dtmfPbx ?? 'rfc4733',
  }
}

/**
 * Get Domain PBX
 * @param {User} user User Data
 * @param {Partial<PBX>} pbx PBX Data
 * @returns Domain PBX
 */
export const getDomainPbx = (user: User, pbx: Partial<PBX>): string => {
  // If user has no pbx, return empty string
  if (!user.pbxId) return '';

  // Get pbx type
  switch (pbx?.modelPbx) {
    case PbxModelEnum.SKYVOICE:
      const skyvoicePbx = pbx as PABX_SKYVOICE;
      return skyvoicePbx?.vpnIp ? skyvoicePbx?.vpnIp : skyvoicePbx?.domainPbx;
    default:
      return pbx?.domainPbx;
  }
}
