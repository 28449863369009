import { Injectable } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  collection,
  collectionData,
  addDoc,
  doc,
  deleteDoc,
  updateDoc
} from '@angular/fire/firestore';
import { Company, PBX } from '@shared/models';
import { getPBXPath } from '@shared/utils/create-path';
import { DocumentData } from 'rxfire/firestore/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PbxService {

  constructor(private firestore: Firestore) { }

  /**
   * Create the obj with the firestore data
   * @param {PBX} pbx PBX data
   * @returns {DocumentData} PBX data in format of a firestore doc data
   */
  private formFirestoreData(companyId: string, pbx: PBX): DocumentData {
    return {
      companyId,
      domainPbx: pbx.domainPbx,
      portSipPbx: pbx.portSipPbx,
      dtmfPbx: pbx.dtmfPbx ?? '',
      namePbx: pbx.namePbx ?? '',
      externalRoute: pbx.externalRoute ?? '',
      attendedTransferCode: pbx.attendedTransferCode ?? '',
      blindTransferCode: pbx.blindTransferCode ?? '',
      modelPbx: pbx.modelPbx,
      extRanges: pbx.extRanges ?? [],
      extRangePrefix: pbx.extRangePrefix ?? '',
    }
  }

  /**
   * Get all pbx
   * @param {string} id tenant ID
   * @returns All pbx that belong to that company
   */
  public fetchAll(id: string): Observable<PBX[]> {
    const col = <CollectionReference<PBX>>(
      collection(this.firestore, getPBXPath(id))
    );
    return collectionData<PBX>(col, { idField: 'id' }).pipe();
  }

  /**
   * Create a new pbx
   * @param {Company} company The company that is the owner of the pbx
   * @param {PBX} pbx Obj that contains the pbx data
    * @returns Pbx ID
   */
  public async create(company: Company, pbx: PBX): Promise<string> {
    const colRef = collection(this.firestore, getPBXPath(company.id));
    const newDoc = await addDoc(colRef, this.formFirestoreData(company.id, pbx));

    return newDoc.id;
  }

  /**
   * Delete a pbx
   * @param {Company} company The company that is the owner of the pbx
   * @param {PBX} pbx The pbx that will be removed
   */
  public async deletePBX(company: Company, pbx: PBX): Promise<void> {
    const docRef = doc(this.firestore, getPBXPath(company.id, pbx.id));
    await deleteDoc(docRef);
  }

  /**
  * Update a pbx
  * @param {Company} company The company that is the owner of the pbx
  * @param {PBX} newPBXData New Data
  * @returns The new pbx
  */
  public update(company: Company, newPBXData: PBX): Promise<void> {
    const docRef = doc(this.firestore, getPBXPath(company.id, newPBXData.id));
    return updateDoc(docRef, this.formFirestoreData(company.id, newPBXData));
  }

}
