import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  doc,
  documentId,
  DocumentReference,
  Firestore,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { environment } from '@environments/environment';
import { AVAILABLE_LANGUAGES, LcsEventDescriptions, LcsEventTypes, PABX_INTEGRATION_OPTIONS } from '@shared/enums';
import { Cluster, Company, Domain, MessageModificationMaxTimeOptions, PBX, PlanTypes, User } from '@shared/models';
import { getCompanyPath } from '@shared/utils/create-path';
import { first, lastValueFrom, Observable } from 'rxjs';
import { UtilsService } from '..';
import { ClusterService } from '../cluster/cluster.service';
import { createClusterBaseData, formUsersEndpointBody } from '@shared/utils';
import { LcsEventsService } from '../lcs-events/lcs-events.service';
import { getDiffieHellman } from 'crypto';
import { getDiffBetweenObj } from '@shared/utils/common';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public companies: Company[] = [];
  public company: Company;
  private url: string = `${environment.baseURL}/tenantHandler/tenant`;

  constructor(
    private firestore: Firestore,
    private http: HttpClient,
    private clusterService: ClusterService,
    private utils: UtilsService,
    private lcsEventsService: LcsEventsService
  ) { }

  /**
   * Get all companies
   * @returns {Company[]}
   */
  public async getCompanies(): Promise<Company[]> {
    // Get path
    const path = getCompanyPath()

    const companies: Company[] = [];

    // Get collection ref
    const col = <CollectionReference<Company>>(
      collection(this.firestore, path)
    );

    // Get doc
    const docsSnapshot = await getDocs<Company>(col);

    // Get companies with the id
    docsSnapshot.docs.forEach((doc) => {
      if (doc.exists) {
        companies.push({ id: doc.id, ...doc.data() });
      }
    });

    return companies
  }

  /**
   *  Get middleware URL
   * @param {string} domain
   * @returns {string} Middleware URL
   */
  private getMiddlewareUrl(domain: string): string {
    const url = `${environment.middlewareBaseUrl}`
    return url.replace('<ip>', domain);
  }

  /**
   * Get Companies Between Dates
   * @param {Date} startDate Start date - Default value: Dec 31 1969
   * @param {Date} endDate Final date - Default value: Current time
   * @returns {Company[]} All companies that were created between these two dates
   */
  public async getCompaniesBetweenDates(
    startDate: Date = new Date(null),
    endDate: Date = new Date()
  ): Promise<Company[]> {
    // Get path
    const path = getCompanyPath()

    const companies: Company[] = [];

    // Get collection ref
    const col = <CollectionReference<Company>>(
      query(
        collection(this.firestore, path),
        where('createdAt', '>=', startDate),
        where('createdAt', '<=', endDate),
      )
    );

    // Get doc
    const docsSnapshot = await getDocs<Company>(col);

    // Get companies with the id
    docsSnapshot.docs.forEach((doc) => {
      if (doc.exists) {
        companies.push({ id: doc.id, ...doc.data() });
      }
    });

    return companies
  }

  /**
   * Get companies by one cluster
   * @param {string} clusterId
   * @returns All companies belong to that cluster
   */
  public getCompaniesByCluster(clusterId: string): Observable<Company[]> {
    // Get path
    const path = getCompanyPath()

    // Find document by clusterId
    const colRef = <CollectionReference<Company>>(
      query(
        collection(this.firestore, path),
        where('clusterId', '==', clusterId)
      )
    );
    return collectionData<Company>(colRef, { idField: 'id' });
  }

  /**
   * Get companies by one cluster
   * @param {string} partnerId
   * @returns All companies belong to that partner
   */
  public getCompaniesByPartner(
    partnerId: string
  ): Observable<Company[]> {
    // Get path
    const path = getCompanyPath()

    // Find document by partnerId
    const colRef = <CollectionReference<Company>>(
      query(
        collection(this.firestore, path),
        where('partner', '==', partnerId)
      )
    );
    return collectionData<Company>(colRef, { idField: 'id' });
  }

  /**
   * Get the data of the company by ID in Firestore
   * @param {string} id - Company identifier
   * @returns {Company} Company obj
   */
  public async getCompanyById(
    id: string
  ): Promise<Company> {

    // Get path
    const path = getCompanyPath()

    // Find document by id
    const colRef = <CollectionReference<Company>>(
      query(
        collection(this.firestore, path),
        where(documentId(), '==', id)
      )
    );

    const querySnapshot = await getDocs<Company>(colRef);

    if (querySnapshot.empty) return null;

    return {
      id: querySnapshot.docs[0].id,
      ...querySnapshot.docs[0].data(),
    };

  }

  /**
   * Set all the companies in the service
   * @param {Company[]} companies all the companies
   */
  public setCompanies(companies: Company[]) {
    this.companies = companies;
  }

  /**
   * Save the current company on the service
   * @param {Company} company Current company
   */
  async setCompany(company: Company) {
    this.company = company;
  }

  /**
   * Create a new company
   * @param {Company} company New company data
   */
  public async createCompany(company: Company): Promise<any>{
    const data = {
      clusterId: company.clusterId,
      domainId: company.id,
      name: company.name,
      cnpj: company.cnpj,
      partner: company.partner ?? '',
      email: company.email ?? '',
      active: company.active ?? true,
      loginOptions: company.loginOptions ?? [],
      simultcalls: company.simultcalls,
      billingCycle: company.billingCycle,
      maxUsersLimit: company.maxUsersLimit ?? 0,
      recordingEnabled: company.recordingEnabled,
      collaborationEnabled: company.collaborationEnabled,
      timezoneName: company.timezoneName,
      language:
        company.language && company.language !== '' ? company.language : AVAILABLE_LANGUAGES.PT_BR,
      hasMultiTalkIntegration: company.hasMultiTalkIntegration,
      recordingRetentionTime: company.recordingRetentionTime,
      planType: company.planType ?? PlanTypes.CLIENT,
      trialTime: company.trialTime ?? null,
      multiTalkCompanyId: company.multiTalkCompanyId ?? -1,
      pbxIntegration: company.pbxIntegration ?? PABX_INTEGRATION_OPTIONS.custom,
      messageModificationMaxTime: company.messageModificationMaxTime ?? MessageModificationMaxTimeOptions.Unlimited
    };
    
    try { 
      const res = await lastValueFrom(this.http.post<any>(this.url, data).pipe(first()));
      this.handleSuccess(
        LcsEventTypes.COMPANY_CREATION, res.id, {
          created: { ...data, id: res.id },
        }
      )
      return res 
    } catch (err){
      console.log(err)
      this.handleError(
        err, null, LcsEventTypes.USER_CREATION
      )
      return;
    }
  }

  /**
   * Update company
   * @param {Cluster} cluster Server
   * @param {Company} company Tenant | Company
   */
  public async updateCompany(cluster: Cluster, company: Company, oldCompany: Company): Promise<any> {

    // Get path
    const path = getCompanyPath(company.id)

    const document = <DocumentReference<Company>>(doc(this.firestore, path));
    try {

    
    await updateDoc(document, {
      name: company.name ?? '',
      media: company.media ?? [],
      cnpj: company.cnpj,
      clusterId: company.clusterId,
      loginOptions: company.loginOptions ?? [],
      partner: company.partner ?? '',
      active: company.active ?? true,
      imgUrl: company.imgUrl ?? '',
      billingCycle: company.billingCycle ?? null,
      email: company.email ?? '',
      needToSync: true,
      updatedAt: serverTimestamp(),
      simultcalls: company.simultcalls,
      maxUsersLimit: company.maxUsersLimit ?? 0,
      timezoneName: company.timezoneName,
      recordingEnabled: company.recordingEnabled ?? false,
      collaborationEnabled: company.collaborationEnabled ?? false,
      language:
        company.language && company.language !== '' ? company.language : AVAILABLE_LANGUAGES.PT_BR,
      hasMultiTalkIntegration: company.hasMultiTalkIntegration ?? false,
      recordingRetentionTime: company.recordingRetentionTime,
      multiTalkCompanyId: company.multiTalkCompanyId ?? -1,
      pbxIntegration: company.pbxIntegration ?? PABX_INTEGRATION_OPTIONS.custom,
      matrixRoomId: company.matrixRoomId ?? '',
      planType: company.planType ?? PlanTypes.CLIENT,
      trialTime: company.trialTime ?? null,
      messageModificationMaxTime: company.messageModificationMaxTime ?? MessageModificationMaxTimeOptions.Unlimited
    });


    this.handleSuccess(LcsEventTypes.COMPANY_UPDATE, company.id , {
      id: company.id,
      diff: getDiffBetweenObj(company, oldCompany, ['id']),
    });

  } catch(err) {
    this.handleError(err, company.id, LcsEventTypes.COMPANY_UPDATE)
    console.log(err)
    return;
  }
  }

  /**
   * Update company
   * @param {Cluster} cluster Server
   * @param {Company} company Tenant | Company
   */
  public async updateClusterCompany(cluster: Cluster, company: Company): Promise<any> {
    const value = {
      clusterid: cluster.id,
      domain: cluster.domain,
      domainId: company.id,
      simultcalls: company.simultcalls,
      recordingEnabled: company.recordingRetentionTime != 0,
    };

    return lastValueFrom(
      this.http.put(this.getMiddlewareUrl(cluster.domain), value)
    );
  }

  async createClusterCompany(company: Company, users?: User[], allPbx?: PBX[]): Promise<any> {
    const cluster: Cluster = await this.clusterService.getById(
      company.clusterId
    );

    const value = createClusterBaseData(company, cluster);

    users?.forEach((user) => {
      let pbx: Partial<PBX>;

      // Get PBX data
      if (company.hasMultiTalkIntegration) {
        pbx = {
          domainPbx: 'multitalk01.leucotron.com.br',
          dtmfPbx: 'rfc4733',
          portSipPbx: '5060'
        }
      } else {
        pbx = this.findCurrentPbx(allPbx, user);
      }

      value.endpoints.push(formUsersEndpointBody(user, pbx));
    });

    return await lastValueFrom(
      this.http.post(this.getMiddlewareUrl(cluster.domain), value)
    );
  }

  /**
     * Find Pbx by user data
     * @param {PBX[]} pbx All PBX company
     * @param {User} user User data
     * @returns {PBX} Pbx data
     */
  private findCurrentPbx(pbx: PBX[], user: User): PBX {
    return pbx.find(value => user.pbxId == value?.id)
  }

  /**
   * Update the company img
   * @param {string} imgUrl Img URL
   * @param {Company} company Company data
   */
  public async updateImgUrl(imgUrl: string, company: Company): Promise<void> {
    // Get path
    const path = getCompanyPath(company.id)

    // Get document ref
    const document = <DocumentReference<Domain>>(doc(this.firestore, path));

    // Update img url
    return updateDoc(document, { imgUrl });
  }

  public deleteCompanyFromFirestore(
    cluster: Cluster,
    domainId: string,
    tenantId: string
  ): Promise<any> {
    return lastValueFrom(
      this.http.delete<any>(this.url, {
        body: { clusterId: cluster?.id, domainId, tenantId },
      })
    );
  }

  public deleteClusterCompany(cluster: Cluster, domainId: string): Promise<Object> {
    return lastValueFrom(
      this.http.delete(
        `${this.getMiddlewareUrl(cluster.domain)}/${domainId}`
      )
    );
  }

  async deleteCompany(
    cluster: Cluster,
    domainId: string,
    tenantId: string
  ): Promise<any> {
    // Delete from Identity Platform and Firestore

    try {
      await this.deleteCompanyFromFirestore(cluster, domainId, tenantId);

      // Delete from server
      await this.deleteClusterCompany(cluster, domainId);

      this.handleSuccess(LcsEventTypes.COMPANY_DELETION, tenantId, {
        deleted: {
          id: tenantId,
        },
      });
    } catch (err) {
      this.handleError(err, tenantId, LcsEventTypes.COMPANY_DELETION);
    }
  }

  setCompanyUsers(companyId: string, users: User[]) {
    localStorage.setItem(`${companyId}_users`, this.utils.usersToString(users));
  }

  async applyChangesToCluster(company: Company): Promise<any> {
    const cluster: Cluster = await this.clusterService.getById(
      company.clusterId
    );

    const value = createClusterBaseData(company, cluster);

    const endpoints: {
      uuid: string;
      extension: string;
      passwordExten: string;
      email: string;
      displayName: string;
      domainPbx: string;
      portSipPbx: string;
    }[] = [];
    const users: User[] = this.utils.stringToUser(
      localStorage.getItem(`${company.id}_users`)
    );
    users.forEach((user) => {
      if (user.needToSync) {
        endpoints.push({
          extension: user.extension,
          passwordExten: user.passwordExten,
          uuid: user.id,
          email: user.email,
          displayName: user.displayName,
          domainPbx: user.domainPbx,
          portSipPbx: user.portSipPbx,
        });
      }
    });
    value.endpoints = endpoints;
    return lastValueFrom(
      this.http.post(this.getMiddlewareUrl(cluster.domain), value)
    );
  }

  /**
   * Update 'needToSync' attribute advising that company needs to sync
   * @param {Company} company Company data
   */
  public needToSync(company: Company): Promise<any> {
    // Get path
    const path = getCompanyPath(company.id)

    // Get document ref
    const document = <DocumentReference<Domain>>(doc(this.firestore, path));

    // Update needToSync
    return updateDoc(document, { needToSync: true, });
  }

  /**
   * Update 'needToSync' attribute advising that company is synchronized
   * @param {Company} company Company data
   */
  public synchronized(company: Company): Promise<any> {
    // Get path
    const path = getCompanyPath(company.id)

    // Get document ref
    const document = <DocumentReference<Domain>>(doc(this.firestore, path));

    // Update needToSync
    return updateDoc(document, { needToSync: false, });
  }

  /**
   * Check if trial ended
   * @param {Company} company Company data
   * @returns {boolean} True if trial ended
   */
  public isTrialFinished(company: Company): boolean {
    return this.hasExpiryDate(company.planType) && company.trialTime?.toDate()?.getTime() < new Date().getTime();
  }

  /**
   * Check if company has expiry date
   * @param {PlanTypes} option Plan Types
   * @returns {boolean} true if company has expiry date
   */
  public hasExpiryDate(option: PlanTypes): boolean {
    switch (option) {
      case PlanTypes.SHOWCASE:
      case PlanTypes.TRIAL:
        return true;

      default:
        return false;
    }
  }

  /**
   * Enable users from the company
   * @param {Company} company Company data
   */
  public async enableUsers(company: Company): Promise<void> {
    if (this.isTrialFinished(company)) {
      const path = `${this.url}/${company.id}/active-users`; // Get path
      await lastValueFrom(this.http.get<void>(path).pipe(first()));
    }
  }

  /**
   * 
   * @param type LCS event type
   * @param companyId Company id
   * @param value The value of the event
   * @returns http res
   */
    public handleSuccess(
      type: LcsEventTypes,
      companyId: string,
      value: any
    ): Promise<void> {
      return this.lcsEventsService.create(
         type,  LcsEventDescriptions.SUCCESS, companyId, value 
      );
    }
  
    /**
     * @param error The error returned from catch
     * @param companyId Company | Tenant Id
     * @param type Type of event
     */
    public handleError(error: any, companyId: string | null, type: LcsEventTypes): void {
      // Log the error
      console.error(error);
  
      // Create a new event
      this.lcsEventsService.create(
        type,  LcsEventDescriptions.ERROR, companyId,  error  
      );
  
      // Throw the error
      throw error;
    }
}
