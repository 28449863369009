export interface MobiPhoneExtensionStatus {
  peer: string;
  uri: string;
  endpointname: string;
  device: string;
  peerstatus: string;
  contactstatus: string;
  state: string;
  roundtripusec: string;
}

export interface PabxExtensionStatus {
  channeltype: string;
  domain: string;
  status: string;
}

export interface ExtensionMonitor {
  id: number;
  ipserver: string;
  event: string;
  uuid: string;
  tenantId: string;
  data: any;
  date: Date;
}