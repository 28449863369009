export enum LcsEventTypes {
  USER_CREATION = 'user_creation',
  USER_DELETION = 'user_deletion',
  USER_UPDATE = 'user_update',
  USER_IMPORT = 'user_import',
  COMPANY_CREATION = 'company_creation',
  COMPANY_DELETION = 'company_deletion',
  COMPANY_UPDATE = 'company_update',
  ERROR = 'error',
}

export enum LcsEventUserCRUDDescriptions {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum LcsEventDescriptions {
  SUCCESS = 'success',
  ERROR = 'error',
}
