export interface IntegrationIcon {
  name: string;
  isSvg: boolean;
}

export interface Integration {
  id: string;
  icon: IntegrationIcon;
  name: string;
  type: string;
  domain: string;
  groups: string[];
  enabled: boolean;
  openInWindow: boolean;
}
