import { Sector } from './sector.model';

export interface PBX {
  id?: string;
  domainPbx: string;
  portSipPbx: string;
  namePbx?: string;
  modelPbx?: string;
  dtmfPbx?: string;
  externalRoute?: number | string[];
  attendedTransferCode?: string;
  blindTransferCode?: string;
  sectors?: Sector[];
  extRanges?: ExtensionRange[];
  extRangePrefix?: string;
}

export interface PABX_SKYVOICE extends PBX {
  pprefix: string;
  bloq: string;
  vpnIp: string;
}

export interface ExtensionRange {
  start: number;
  end: number;
}

export interface PBXExtension {
  id?: string;
  extension: string;
  pass: string;
  userId?: string;
  pbxId: string;
}

export enum PbxModelEnum {
  ISION_IP = 'ision',
  FLUX_IP = 'flux',
  OTHER = 'other',
  SKYVOICE = 'skyvoice',
}

export interface PbxModel {
  name: PbxModelEnum;
  displayName: string;
}
