import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { AiServiceFilterParams, TranscriptionResponse } from '@shared/models';
import { AuthService, CryptoService } from '@core/services';
import { formParams } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class TranscriptionService {

  public inputText = new BehaviorSubject<TranscriptionResponse>(null);
  public isLoading: boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public cript: CryptoService,
  ) { }

  /**
  * Get all transcriptions/summarys
  * @returns {AiService[]} All files 
  */
  public fetchAll(filter: AiServiceFilterParams): Observable<HttpResponse<any>> {

    // Get path
    const path = environment.baseURL + '/aiService/filesAI';

    return this.http.get<any>(path, {
      observe: 'response',
      params: formParams(filter),
    });
  }

  public async aiServices(formData: FormData, flagWithFile) {
    try {
      let path: string;
      if (flagWithFile === false) {
        path = environment.aiApiURL + '/myAIhelper/withoutFile';
      } else {
        path = environment.aiApiURL + '/myAIhelper/withFile';
        const email = (await this.authService.loggedUser()).email;
        formData.append("fileOwnerEmail", email);
      }
      this.isLoading = true;

      formData.append("leucotronService", "lcs");
      formData.append("companyUser", "Matrix-ol2p2");

      const texts = await lastValueFrom(this.http.post<TranscriptionResponse>(path, formData))
      // this.inputText.next(texts);
      if (texts.processStatus === "error") {
        throw new Error
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }

  }

  public async getKey() {
    const email = (await this.authService.loggedUser()).email;

    // Get path
    const path = environment.baseURL + '/aiKey/getKey?email=' + email;

    const resp = await lastValueFrom(this.http.get<any>(path, {
      observe: 'response',
    }))
    const data = resp.body['data'].key;
    const key = this.cript.decryptText(data, environment.criptokey)

    return key;
  }

}
