import { Injectable } from '@angular/core';
import {
  CollectionReference,
  DocumentReference,
  Firestore,
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { CompanyService } from '../company/company.service';
import { Observable } from 'rxjs';
import { getProviderPath } from '@shared/utils';
import { SkyvoiceProvider } from '@shared/models/skyvoice-provider.model';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  public providers: any[] = [];
  public $providers: Observable<any[]>;
  public equipId: any;

  constructor(
    private companyService: CompanyService,
    private firestore: Firestore
  ) {}

  public async getProviders(): Promise<any[]> {
    const providers: any[] = [];
    // Get collection ref
    const col = <CollectionReference<any>>(
      query(
        collection(this.firestore, `devices`),
        where('companyId', '==', this.companyService.company.id)
      )
    );

    // Get doc
    const docsSnapshot = await getDocs<any>(col).then(async (res) => {
      // Get companies with the id
      this.equipId = res.docs[0].id;
      const appQuery = query(
        collection(this.firestore, `devices/${res.docs[0].id}/providers`)
      );

      const ds = await getDocs(appQuery);

      ds.docs.forEach((provesion) => {
        providers.push({ ...provesion.data(), id: provesion.id });
      });
    });

    return providers;
  }

  async getEquipId() {
    const col = <CollectionReference<any>>(
      query(
        collection(this.firestore, `devices`),
        where('companyId', '==', this.companyService.company.id)
      )
    );
    const docsSnapshot = await getDocs<any>(col);

    const equipId = docsSnapshot.docs[0].id;

    this.equipId = equipId;

    return equipId;
  }

  /**
   * Update provider
   * @param {string} equipId Equipment ID
   * @param {any} prov Skyvoice provider to be created
   */
  public create(equipId: string, prov: SkyvoiceProvider) {
    const path = getProviderPath(equipId);
    return addDoc(collection(this.firestore, path), prov);
  }
}
