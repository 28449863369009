export enum EXTENSION_ACTIONS {
  insert = 'inserir_ramal',
  list = 'listar_ramais',
  update = 'alterar_ramal',
  delete = 'deletar_ramal',
};

export enum COMPANY_ACTIONS {
  insert = 'inserir_cliente',
  list = 'listar_clientes',
  update = 'alterar_cliente',
  delete = 'deletar_cliente',
  block = 'bloquear_cliente',
  unblock = 'desbloquear_cliente',
};
