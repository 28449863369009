export interface Partner {
  id?: string;
  name: string;
  email: string;
  clusterId: string;
  usedLicenses?: number;
  licenseLimit?: number;
  cnpj?: string;
  usedSimultCalls?: number;
  simultCallsLimit?: number;
  language: string;
  companyIds?: string[];
  hasMultiTalk?: boolean;
  type?: PartnerType;
}

export interface PartnerRanking {
  availableDates?: string[]
  ranking: PartnerCounterHistory[]
}

export interface PartnerCounterHistory {
  id?: number;
  date: Date;
  partnerId: string;
  companies: number;
  users: number;
}

export enum PartnerType {
  dealership = 'dealership',
  corporationClient = 'corporationClient',
  test = 'test',
}
