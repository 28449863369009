export interface ITriggerEmail {
  from?: string;
  replyTo?: string;
  to?: string | string[];
  toUids?: string[];
  cc?: string | string[];
  ccUids?: string[];
  bcc?: string | string[];
  bccUids?: string[];
  headers?: any;
  message?: {
    messageId?: string;
    subject: string;
    text?: string;
    html?: string;
    amp?: string;
    attachments?: Attachment[];
  };
  template?: Template;
}

interface Attachment {
  filename: string;
  path: string;
}

interface Template {
  name: string;
  data: {
    username?: string;
    name?: string;
    imagePath?: string;
    partial?: boolean;
    html?: string;
    text?: string;
  };
}
