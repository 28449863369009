import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;

  constructor() {
    this.start();
  }

  /**
   * Start the communication with the socket
   */
  public start() {
    const socketUrl = environment.socketUrl;

    if (!this.socket) {
      this.socket = io(socketUrl, { secure: true });
      console.log("connect")
    }
  }

  /**
   * Connect with the socket previous started
   */
  public connect(): void {
    if (this.socket) {
      this.socket.connect();
    }
  }

  /**
   * Close de connection
   */
  public stop(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket.removeAllListeners(); // Remove os ouvintes
    }
  }

  /**
   * Send a message to a topic
   * @param {string} topic Contain the topic event
   * @param data Contain the data that will be sent to the topic
   */
  public sendMessage(data: [string, any]): void {
    this.socket.emit('events', data);
  }

  /**
   * Define the topic and the action that will be executed
   * @param {string} topic Contain the topic event
   * @param {Function} action Callback function that represents the action of the topic
   */
  public listenToTopic(topic: string, action: (...args: any[]) => void): void {
    this.socket.on(topic, action);
  }

  public remove(topic: string): void {
    this.socket.removeListener(topic);
  }
}
