import { Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor(
    private utilsService: UtilsService,
  ) { }

  /**
   * Generate CSV
   * @param {string[]} columns Column attributes
   * @param {string[]} keys Attributes that will be used as data to generate CSV
   * @param {any[]} data Array with all data that will be used to generate CSV
   * @param {string} fileName File name
   */
  public exportCSV(columns: string[], keys: string[], data: any[], fileName: string) {

    // Form the columns
    let csvContent = "";
    let row = columns.join() + ',';
    csvContent += row + '\r\n';

    // Form the rows
    for (const obj of data) {
      const csvData = []

      keys.forEach(value => {
        csvData.push(obj[value])
      });

      row = csvData.join() + ',';
      csvContent += row + '\r\n';
    }

    // Generate URL
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);

    // Download file
    this.utilsService.downloadFile(encodedUri, fileName);
  }

}
