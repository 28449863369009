import { Injectable } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  collection,
  collectionData,
  addDoc,
  doc,
  deleteDoc,
  updateDoc
} from '@angular/fire/firestore';
import { Company, Sector } from '@shared/models';
import { getSectorPath } from '@shared/utils/create-path';
import { DocumentData } from 'rxfire/firestore/interfaces';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SectorService {

  constructor(private firestore: Firestore) { }

  /**
   * Create the obj with the firestore data
   * @param {Sector} sector Sector data
   * @returns {DocumentData} Sector data in format of a firestore doc data
   */
  private formFirestoreData(companyId: string, sector: Sector): DocumentData {
    return {
      companyId,
      name: sector.name,
      pbxId: sector.pbxId,
      extension: sector.extension,
    }
  }

  /**
   * Get the sectors of PBX
   * @param {Sector[]} sectors All sectors
   * @param {string} pbxId PBX id
   * @returns {Sector[]} All PBX sectors
   */
  public findPBXSectors(sectors: Sector[], pbxId: string): Sector[] {
    return sectors.filter(sector => pbxId === sector.pbxId);
  }

  /**
   * Get all sector
   * @param {string} id tenant ID
   * @returns All sectors that belong to that company
   */
  public fetchAll(id: string): Observable<Sector[]> {
    const col = <CollectionReference<Sector>>(
      collection(this.firestore, getSectorPath(id))
    );
    return collectionData<Sector>(col, { idField: 'id' });
  }

  /**
   * Create a list of sectors
   * @param {Company} company The company that is the owner of the sector
   * @param {Sector[]} sectors Obj that contains the sector data
   * @param {string} pbxId PBX ID
   * @returns Sector ID
   */
  public async createSectors(company: Company, sectors: Sector[], pbxId: string): Promise<void> {
    const path = getSectorPath(company.id)

    for (const sector of sectors) {
      const colRef = collection(this.firestore, path);
      await addDoc(colRef, this.formFirestoreData(company.id, { ...sector, pbxId }));
    }
  }

  /**
   * Delete a list of sectors
   * @param {Company} company The company that is the owner of the sector
   * @param {Sector[]} sectors The sectors that will be removed
   */
  public async deleteSectors(company: Company, sectors: Sector[]): Promise<void> {
    for (const sector of sectors) {
      const path = getSectorPath(company.id, sector.id)
      const docRef = doc(this.firestore, path);
      await deleteDoc(docRef);
    }

  }

}
