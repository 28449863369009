import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  CollectionReference,
  Firestore,
} from '@angular/fire/firestore';
import { environment } from '@environments/environment';
import { Company, ITriggerEmail, LcsUser, Partner, User } from '@shared/models';
import { first, lastValueFrom } from 'rxjs';
import {
  newPartnerHtmlTemplate,
  newUserHtmlTemplate,
  resetPasswordHtmlTemplate,
  salesDeleteHtmlTemplate,
  salesNewCompanyHtmlTemplate,
  salesNewPartnerHtmlTemplate,
  salesUpdateHtmlTemplate,
} from './utils/email.templates';
import { UsersService, UtilsService } from '..';
import { TranslateService } from '@ngx-translate/core';
import { AVAILABLE_LANGUAGES } from '@shared/enums';
import { CompanyEmailData, PartnerEmailData } from './utils/email.model';
import { CompanyEmailDataKeys, PartnerEmailDataKeys } from './utils/email.constants';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private url: string = `${environment.baseURL}/misc/resetpasslink`;

  constructor(
    private userService: UsersService,
    private utilsService: UtilsService,
    private http: HttpClient,
    private firestore: Firestore,
    private translate: TranslateService
  ) { }

  async sendForgotPasswordEmail(email: string): Promise<void> {
    await this.buildForgotPasswordEmailTemplate(
      email,
      this.translate.currentLang
    ).catch((err) => {
      throw err;
    });
  }

  async sendNewUserEmail(email: string, company: Company): Promise<void> {
    await this.buildNewUserEmailTemplate(
      email,
      company,
      company.language
    ).catch((err) => {
      throw err;
    });
  }

  async sendNewPartnerEmail(partner: Partner): Promise<void> {
    await this.buildNewPartnerEmailTemplate(partner).catch((err) => {
      throw err;
    });
  }

  async sendSalesPartnerEmail(partner: Partner, userId: string, newData: Partner = null): Promise<void> {
    const copyPartner = { ...partner };
    const copyNewData = newData ? { ...newData } : null;
    let hasValidChanges = false;

    if (copyNewData) {
      const diff = this.diffBetweenObjs<Partner>(copyPartner, copyNewData);

      diff.forEach(value => {
        if (PartnerEmailDataKeys.includes(value)) hasValidChanges = true;
      })

      if (!hasValidChanges) return;
    }

    await this.buildSalesPartnerHtmlTemplate(copyPartner, userId, copyNewData).catch((err) => {
      throw err;
    });
  }

  async sendNewLcsUserEmail(user: LcsUser, lang: string = AVAILABLE_LANGUAGES.PT_BR): Promise<void> {
    await this.buildNewLcsUserEmailTemplate(user, lang).catch((err) => {
      throw err;
    });
  }

  async sendSalesCompanyHtmlTemplate(company: Company, partners: Partner[], userEmail: string, newData: Company = null): Promise<void> {
    const copyCompany = { ...company };
    const copyNewData = newData ? { ...newData } : null;
    let hasValidChanges = false;

    if (copyNewData) {
      const diff = this.diffBetweenObjs<Company>(copyCompany, copyNewData);

      diff.forEach(value => {
        if (CompanyEmailDataKeys.includes(value)) hasValidChanges = true;
      })

      if (!hasValidChanges) return;
    }

    await this.buildSalesCompanyHtmlTemplate(copyCompany, partners, userEmail, copyNewData).catch((err) => {
      throw err;
    });
  }

  /**
   * Send email to sales when company is deleted
   * @param {Company} company Company data
   * @param {Partner[]} partners Partners data
   * @param {string} userEmail User that realize the changes
   */
  async sendSalesCompanyDeletedEmail(company: Company, partners: Partner[], userEmail: string): Promise<void> {
    const copyCompany = { ...company };

    await this.buildSalesCompanyHtmlTemplate(copyCompany, partners, userEmail, null, true).catch((err) => {
      throw err;
    });
  }

  /**
    * Get diff values
    * @param {T} obj1 First obj
    * @param {T} obj2 Second obj
    * @returns {string[]} Array of keys that contains diff values
  */
  private diffBetweenObjs<T>(obj1: T, obj2: T): string[] {
    const diff = [];
    Object.keys(obj1).forEach(key => {
      if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) diff.push(key);
    });
    return diff;
  }

  /**
    * Get diff values
    * @param {T} obj1 First obj
    * @param {T} obj2 Second obj
    * @returns {string[]} Array of keys that contains diff values
  */
  private formAttrsIntoInfo<T>(obj1: T): string {
    let infoValue = '';

    // Pass to each key
    Object.keys(obj1).forEach(key => {
      // Get the intl of the key
      const intlKey = this.translate.instant(key);

      // Add on infoValue the HTML
      switch (key) {
        case 'email':
        case 'adminEmail':
          infoValue += `<span style="color: #000000;">
          <strong>${intlKey}: </strong> <a href="mailto:${obj1[key]}"> ${obj1[key]} </a>
          </span><br/>`
          break;
        default:
          infoValue += `<span style="color: #000000;"><strong>${intlKey}: </strong>${obj1[key]}</span><br/>`
          break;
      }
    });

    return infoValue;
  }

  /**
   * Form Info By Diff
   * @param {T} oldData old Data
   * @param {T} newData new Data
   * @param {string} lang Language
   * @returns {string} Email info
   */
  private formInfoByDiff<T>(oldData: T, newData: T, lang: string = AVAILABLE_LANGUAGES.PT_BR): string {
    const diff = this.diffBetweenObjs<T>(oldData, newData);
    const from: { [key: string]: string } = { pt: 'DE', en: 'FROM', es: 'DE' };
    const to: { [key: string]: string } = { pt: 'PARA', en: 'TO', es: 'A' };

    let infoFromValue = `<p><span style="color: #000000;"><strong>${from[lang]}:<br/></strong></span>`;
    let infoToValue = `<p><span style="color: #000000;"><strong>${to[lang]}:<br/></strong></span>`;

    diff.forEach(key => {
      // Get the intl of the key
      const intlKey = this.translate.instant(key);

      // Add on infoValue the HTML
      switch (key) {
        case 'email':
        case 'adminEmail':
          infoFromValue += `<span style="color: #000000;">
                <strong>${intlKey}: </strong> <a href="mailto:${oldData[key]}"> ${oldData[key]} </a>
                </span><br/>`
          infoToValue += `<span style="color: #000000;">
                <strong>${intlKey}: </strong> <a href="mailto:${newData[key]}"> ${newData[key]} </a>
                </span><br/>`
          break;
        default:
          infoFromValue += `<span style="color: #000000;"><strong>${intlKey}: </strong>${oldData[key]}</span><br/>`
          infoToValue += `<span style="color: #000000;"><strong>${intlKey}: </strong>${newData[key]}</span><br/>`
          break;
      }
    })

    infoFromValue += '</p><br/>';
    infoToValue += '</p><br/>';

    return infoFromValue + infoToValue;
  }

  private async buildNewUserEmailTemplate(
    email: string,
    company: Company,
    lang: string = AVAILABLE_LANGUAGES.PT_BR
  ): Promise<string> {
    const { link } = await lastValueFrom(
      this.http
        .get<{ link: string }>(
          `${this.url}?email=${email}&tenantId=${company.id}`
        )
        .pipe(first())
    ).catch((err) => {
      throw err;
    });

    const { code, apiKey } = this.getQueryParams(link);
    const user: User = await this.userService.getUserByEmail(email, company);

    const subjectLanguage: { [key: string]: string } = {
      pt: 'Bem-vindo(a) ao Mobi',
      en: 'Welcome to Mobi',
      es: 'Bienvenido(a) a Mobi',
    };
    let sendEmail: string = email;

    if (email.includes('mobiphone.net.br')) {
      sendEmail = company.email;
    }

    const data: ITriggerEmail = {
      to: `${user.displayName} <${sendEmail}>`,
      from: 'Mobi Leucotron <no-reply-lcs@leucotron.com.br>',
      message: {
        subject: subjectLanguage[lang],
        html: newUserHtmlTemplate(
          user,
          'resetPassword',
          code,
          apiKey,
          company,
          lang,
          email.includes('mobiphone.net.br')
        ),
      },
    };

    const path: string = 'mail';
    const newMail = await addDoc<any>(
      collection(this.firestore, path) as CollectionReference<any>,
      data
    );

    return newMail.id;
  }

  private async buildNewPartnerEmailTemplate(
    partner: Partner
  ): Promise<string> {
    const lang = partner.language ?? AVAILABLE_LANGUAGES.PT_BR;

    const { link } = await lastValueFrom(
      this.http
        .get<{ link: string }>(`${this.url}?email=${partner.email}`)
        .pipe(first())
    ).catch((err) => {
      throw err;
    });

    const { code, apiKey } = this.getQueryParams(link);

    const subjectLanguage: { [key: string]: string } = {
      pt: 'Boas vindas ao Leucotron Cloud Service!',
      en: 'Welcome to Leucotron Cloud Service',
      es: '¡Bienvenido al servicio en la nube de Leucotron!',
    };

    const data: ITriggerEmail = {
      to: `${partner.name} <${partner.email}>`,
      message: {
        subject: subjectLanguage[lang],
        html: newPartnerHtmlTemplate('setPassword', code, apiKey, lang),
      },
    };

    const path: string = 'mail';
    const newMail = await addDoc<any>(
      collection(this.firestore, path) as CollectionReference<any>,
      data
    );

    return newMail.id;
  }

  private async buildSalesPartnerHtmlTemplate(partner: Partner, userId: string, newData: Partner = null): Promise<any> {
    const lang = AVAILABLE_LANGUAGES.PT_BR;

    const intlPartner: { [key: string]: string } = {
      pt: `Concessionária`,
      en: `Concessionaire`,
      es: `Concesionario`,
    };

    const subjectLanguage: { [key: string]: string } = {
      pt: `${newData ? 'Atualização' : 'Novo Cadastro'} | ${intlPartner[lang]}: ${partner.name}`,
      en: `${newData ? 'Update' : 'New Registration'} | ${intlPartner[lang]}: ${partner.name}`,
      es: `${newData ? 'Actualizar' : 'Nuevo registro'} | ${intlPartner[lang]}: ${partner.name}`,
    };

    let info = `<p style="color: #000000;"><strong>${intlPartner[lang]}:</strong> ${partner.name}</p><br/>`;

    // Get only necessary values
    const filteredPartnerData = this.utilsService.formPartnerEmailData(partner);

    if (newData) {
      const filteredNewData = this.utilsService.formPartnerEmailData(newData); // Get only necessary values
      info += this.formInfoByDiff(filteredPartnerData, filteredNewData, lang);
    } else {
      // Set info from attr
      info = this.formAttrsIntoInfo<CompanyEmailData>(filteredPartnerData);
    }

    const data: ITriggerEmail = {
      to: environment.salesEmail,
      from: 'LCS Leucotron <no-reply-lcs@leucotron.com.br>',
      message: {
        subject: subjectLanguage[lang],
        html: newData
          ? salesUpdateHtmlTemplate(info, userId, lang)
          : salesNewPartnerHtmlTemplate(info, userId, lang),
      },
    };

    const path: string = 'mail';
    const newMail = await addDoc<any>(collection(this.firestore, path) as CollectionReference<any>, data);

    return newMail.id;
  }

  /**
   * Build sales company template
   * @param {Company} company Company data
   * @param {Partner[]} partners Partners data
   * @param {string} userId User that realize the changes
   * @param {Company} newData Company new data
   * @param {boolean} deleted If company was deleted
   */
  private async buildSalesCompanyHtmlTemplate(
    company: Company,
    partners: Partner[],
    userId: string,
    newData: Company = null,
    deleted: boolean = false
  ): Promise<any> {
    const lang = AVAILABLE_LANGUAGES.PT_BR;

    // Get current partner to this company
    const currentPartner = partners.find(p => p.id == company.partner);

    // Set 'to'
    const to = currentPartner
      ? [...environment.salesEmail, `Parceiro <${currentPartner.email}>`]
      : environment.salesEmail

    const intlCompany: { [key: string]: string } = {
      pt: `Empresa`,
      en: `Company`,
      es: `Empresa`,
    };

    const intlPartner: { [key: string]: string } = {
      pt: `Concessionária`,
      en: `Concessionaire`,
      es: `Concesionario`,
    };

    const subjectLanguage: { [key: string]: string } = {
      pt: `${deleted ? 'Exclusão' : newData ? 'Atualização' : 'Novo Cadastro'} | ${intlCompany[lang]}: ${company.name}`,
      en: `${deleted ? 'Deletion' : newData ? 'Update' : 'New Registration'} | ${intlCompany[lang]}: ${company.name}`,
      es: `${deleted ? 'Exclusión' : newData ? 'Actualizar' : 'Nuevo registro'} | ${intlCompany[lang]}: ${company.name}`,
    };

    // Init info flag
    let info = '';

    // Get only necessary values
    const filteredCompanyData = this.utilsService.formCompanyEmailData(company, partners);

    if (newData) {
      // Get only necessary values
      const filteredNewData = this.utilsService.formCompanyEmailData(newData, partners);

      // Add default value
      info = `
      <p style="color: #000000;">
        <strong>${intlCompany[lang]}:</strong> ${filteredNewData.company}
      </p>
      <p style="color: #000000;">
        <strong>${intlPartner[lang]}:</strong> ${filteredNewData.partner}
      </p><br/>
      `;

      // Add info from diff data
      info += this.formInfoByDiff(filteredCompanyData, filteredNewData, lang);
    } else {

      // Set info from attr
      info = this.formAttrsIntoInfo<CompanyEmailData>(filteredCompanyData);
    }

    const data: ITriggerEmail = {
      to,
      from: 'LCS Leucotron <no-reply-lcs@leucotron.com.br>',
      message: {
        subject: subjectLanguage[lang],
        html: deleted
          ? salesDeleteHtmlTemplate(info, userId, lang)
          : newData
            ? salesUpdateHtmlTemplate(info, userId, lang)
            : salesNewCompanyHtmlTemplate(info, userId, lang),
      },
    };

    const path: string = 'mail';
    const newMail = await addDoc<any>(collection(this.firestore, path) as CollectionReference<any>, data);

    return newMail.id;
  }

  /**
   * Build new lcs user email template
   * @param {LcsUser} user
   * @param {string} lang
   * @returns {Promise<string>} Email doc id
   */
  private async buildNewLcsUserEmailTemplate(
    user: LcsUser,
    lang: string
  ): Promise<string> {
    const { link } = await lastValueFrom(
      this.http
        .get<{ link: string }>(`${this.url}?email=${user.email}`)
        .pipe(first())
    ).catch((err) => {
      throw err;
    });

    const { code, apiKey } = this.getQueryParams(link);

    const subjectLanguage: { [key: string]: string } = {
      pt: 'Bem-vindo(a) ao Leucotron Cloud Service',
      en: 'Welcome to Leucotron Cloud Service',
      es: 'Bienvenido(a) a Leucotron Cloud Service',
    };

    const data: ITriggerEmail = {
      to: `<${user.email}>`,
      message: {
        subject: subjectLanguage[lang],
        html: newPartnerHtmlTemplate('setPassword', code, apiKey, lang),
      },
    };

    const path: string = 'mail';
    const newMail = await addDoc<any>(
      collection(this.firestore, path) as CollectionReference<any>,
      data
    );

    return newMail.id;
  }

  private async buildForgotPasswordEmailTemplate(
    email: string,
    lang: string = AVAILABLE_LANGUAGES.PT_BR
  ): Promise<string> {
    const { link } = await lastValueFrom(
      this.http
        .get<{ link: string }>(`${this.url}?email=${email}`)
        .pipe(first())
    ).catch((err) => {
      throw err;
    });

    const { code, apiKey } = this.getQueryParams(link);

    const subjectLanguage: { [key: string]: string } = {
      pt: 'Redefinir senha do Leucotron Cloud Service',
      en: 'Reset Leucotron Cloud Service password',
      es: 'Restablecer la contraseña del Leucotron Cloud Service',
    };

    const data: ITriggerEmail = {
      to: email,
      message: {
        subject: subjectLanguage[lang],
        html: resetPasswordHtmlTemplate('resetPassword', code, apiKey, lang),
      },
    };

    const path: string = 'mail';
    const newMail = await addDoc<any>(
      collection(this.firestore, path) as CollectionReference<any>,
      data
    );

    return newMail.id;
  }

  private getQueryParams(link: string): { code: string; apiKey: string } {
    const queryParams: string = (link as string).split('?')[1];
    const params: string[] = queryParams.split('&');
    const code: string = params
      .find((param) => param.includes('oobCode'))
      .split('=')[1];
    const apiKey: string = params
      .find((param) => param.includes('apiKey'))
      .split('=')[1];

    return { code, apiKey };
  }
}
