import { AVAILABLE_TIMEZONE_NAME } from "@shared/enums";

export interface Language {
  code: string;
  name: string;
}

export interface TimeZone {
  country: string;
  region: TimeZoneRegion[];
}

interface TimeZoneRegion {
  name: string;
  tz: string;
}


export const LANGUAGES: Language[] = [
  { code: 'en', name: 'language.english' },
  { code: 'es', name: 'language.spanish' },
  { code: 'pt', name: 'language.portuguese' },
];

export const MONTHS_ABBREVIATION = [
  "january.short",
  "february.short",
  "march.short",
  "april.short",
  "may.short",
  "june.short",
  "july.short",
  "august.short",
  "september.short",
  "october.short",
  "november.short",
  "december.short"
];


export const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december"
];



export const DEFAULT_TIMEZONE_NAME = AVAILABLE_TIMEZONE_NAME.SAO_PAULO;

export const TIMEZONE_NAMES: TimeZone[] = [
  {
    country: 'Brasil',
    region: [
      { name: 'São Paulo', tz: AVAILABLE_TIMEZONE_NAME.SAO_PAULO },
      { name: 'Belém', tz: AVAILABLE_TIMEZONE_NAME.BELEM },
      { name: 'Manaus', tz: AVAILABLE_TIMEZONE_NAME.MANAUS },
      { name: 'Rio Branco', tz: AVAILABLE_TIMEZONE_NAME.RIO_BRANCO },
      { name: 'Ilha Fernando de Noronha', tz: AVAILABLE_TIMEZONE_NAME.NORONHA },
    ],
  },
  {
    country: 'México',
    region: [
      { name: 'Ciudad de México', tz: AVAILABLE_TIMEZONE_NAME.MÉXICO_CITY },
      { name: 'Tijuana', tz: AVAILABLE_TIMEZONE_NAME.TIJUANA },
      { name: 'Baja California Sur', tz: AVAILABLE_TIMEZONE_NAME.BAJA_SUR },
    ],
  },
]
