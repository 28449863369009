export enum LcsEventTypes {
  USER_CREATION = 'user_creation',
  USER_DELETION = 'user_deletion',
  USER_UPDATE = 'user_update',
  USER_IMPORT = 'user_import',
  COMPANY_CREATION = 'company_creation',
  COMPANY_DELETION = 'company_deletion',
  COMPANY_UPDATE = 'company_update',
  ERROR = 'error',
  COLLABORATION_CLUSTER_CREATION = 'collaboration_cluster_creation',
  COLLABORATION_CLUSTER_DELETION = 'collaboration_cluster_deletion',
  COLLABORATION_CLUSTER_UPDATE = 'collaboration_cluster_update',
  COLLABORATION_CLUSTER_SYNC = 'collaboration_cluster_sync',
  MEDIA_CLUSTER_CREATION = 'media_cluster_creation',
  MEDIA_CLUSTER_DELETION = 'media_cluster_deletion',
  MEDIA_CLUSTER_UPDATE = 'media_cluster_update',
  MEDIA_CLUSTER_SYNC = 'media_cluster_sync',
  RECORDING_CLUSTER_CREATION = 'recording_cluster_creation',
  RECORDING_CLUSTER_DELETION = 'recording_cluster_deletion',
  RECORDING_CLUSTER_UPDATE = 'recording_cluster_update',
  RECORDING_CLUSTER_SYNC = 'recording_cluster_sync',
}

export enum LcsEventUserCRUDDescriptions {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum LcsEventDescriptions {
  SUCCESS = 'success',
  ERROR = 'error',
}
