export const PartnerEmailDataKeys = [
  "name",
  "email",
  "cnpj",
  "simultCallsLimit",
]

export const CompanyEmailDataKeys = [
  "name",
  "partner",
  "email",
  "cnpj",
  "maxUsersLimit",
  "simultcalls",
  "billingCycle",
  "recordingRetentionTime",
  "trialTime",
  "planType",
  "status",
]
