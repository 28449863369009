interface CustomIcon {
  name: string;
  path: string;
}

export const CUSTOM_ICONS: CustomIcon[] = [
  { name: 'company', path: 'assets/icons/company.svg' },
  { name: 'rows', path: 'assets/icons/rows.svg' },
  { name: 'elements', path: 'assets/icons/elements.svg' },
  { name: 'partner', path: 'assets/icons/handshake.svg' },
  { name: 'cloud', path: 'assets/icons/cloud.svg' },
  { name: 'group', path: 'assets/icons/group.svg' },
  { name: 'server', path: 'assets/icons/server.svg' },
  { name: 'cloud-computing', path: 'assets/icons/cloud-computing.svg' },
  { name: 'customer', path: 'assets/icons/customer.svg' },
  { name: 'new-document', path: 'assets/icons/new-document.svg' },
  { name: 'upload', path: 'assets/icons/upload.svg' },
  { name: 'download', path: 'assets/icons/download.svg' },
  { name: 'play', path: 'assets/icons/play.svg' },
  { name: 'pause', path: 'assets/icons/pause.svg' },
  { name: 'rec', path: 'assets/icons/rec.svg' },
  { name: 'file-export', path: 'assets/icons/file-export.svg' },
  { name: 'filter', path: 'assets/icons/filter.svg' },
  { name: 'calendar', path: 'assets/icons/calendar.svg' },
  { name: 'clear-filter', path: 'assets/icons/clear-filter.svg' },
  { name: 'dashboard', path: 'assets/icons/dashboard.svg' },
  { name: 'incomming-call', path: 'assets/icons/incomming-call.svg' },
  { name: 'missed-call-in', path: 'assets/icons/missed-call-in.svg' },
  { name: 'out-call', path: 'assets/icons/out-call.svg' },
  { name: 'simultCalls', path: 'assets/icons/simultCalls.svg' },
  { name: 'storage', path: 'assets/icons/storage.svg' },
  { name: 'clock', path: 'assets/icons/clock.svg' },
  { name: 'settings', path: 'assets/icons/settings.svg' },
  { name: 'copy', path: 'assets/icons/copy.svg' },
  { name: 'terminal-icon', path: 'assets/icons/terminal_icon.svg' },
  { name: 'equipments', path: 'assets/icons/equipments.svg' },
  { name: 'robot', path: 'assets/icons/robot.svg' },
];
