import { BillingCycle, BillingStatus, PABX_INTEGRATION_OPTIONS } from "@shared/enums";
import { PlanTypes } from "./company.model";

export interface BillingDataSource {
  id?: number;
  date: string;
  user: string;
  partner: string;
  company: string;
  simultCalls: number;
  userLimit: number;
  recordingRetentionTime: number;
  status: string;
  trialTime: string;
  pbx: string;
  billingCycle: string;
  planType: string;
  partnerType: string;
};

export interface AdvancedBillingFilter {
  page?: number;
  limit?: number;
  initialDate?: string;
  finalDate?: string;
  partner?: string;
  company?: string;
  status?: string;
  planType?: string;
  billingCycle?: string;
  partnerType?: string;
}

export interface BillingSearchResult {
  billings: Billing[];
  context: any;
}

export interface Billing {
  id?: number,
  date?: string,
  userId: string,
  partnerId: string,
  partnerType?: string,
  companyId: string,
  simultCalls: number,
  maxUsersLimit: number,
  recordingRetentionTime: number,
  status: BillingStatus,
  pbxIntegration: PABX_INTEGRATION_OPTIONS,
  price?: number,
  trialTime: string,
  billingCycle: BillingCycle,
  planType?: PlanTypes;
}
