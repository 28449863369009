import { Injectable } from '@angular/core';
import {
  Auth,
  authState,
  idToken,
  signInWithEmailAndPassword,
  signOut,
  User,
  confirmPasswordReset,
  UserCredential,
} from '@angular/fire/auth';
import { doc, DocumentReference, Firestore } from '@angular/fire/firestore';
import { LcsUser, Roles } from '@shared/models';
import { docData } from 'rxfire/firestore';
import { lastValueFrom, first, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<LcsUser>;
  uid: string;
  private isPartner: boolean = false;

  constructor(private auth: Auth, private firestore: Firestore) {
    this.user$ = authState(this.auth).pipe(
      switchMap((user) => {
        if (user) {
          this.uid = user.uid;
          const doca = doc(this.firestore, `lcs_users/${user.uid}`) as DocumentReference<LcsUser>;
          return docData<LcsUser>(doca, { idField: 'uid' });
        }
        return of(null);
      })
    );
    // this.user$ = authState(this.auth);
    // this.user$.subscribe((user) => {
    //   console.log('USER', user);
    //   this.uid = user?.uid;
    // });
  }

  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  signOut(): Promise<void> {
    return signOut(this.auth);
  }

  idToken(): Observable<string> {
    return idToken(this.auth);
  }

  loggedIn(): Observable<boolean> {
    return this.user$.pipe(
      take(1),
      map((user) => !!user)
    );
  }

  async loggedUser(): Promise<LcsUser> {
    return await lastValueFrom(this.user$.pipe(first()));
  }

  async verifyIfItIsPartner(): Promise<boolean> {
    // const uid = this.uid ?? (await this.loggedUser()).uid;
    // this.isPartner = await this.partnerService.isPartner(uid);
    // return this.isPartner;
    const user = await this.loggedUser();
    return this.isPartner = user.roles.includes(Roles.PARTNER);
  }

  getIsPartner(): boolean {
    return this.isPartner;
  }

  /**
   * Get logged partner id
   * @returns {string} logged partner id
   */
  public async getLoggedPartnerId(): Promise<string> {
    // Get current user
    const user = await this.loggedUser();

    // Return partner id
    return user.roles.includes(Roles.PARTNER)
      ? user.uid
      : null
  }

  confirmPasswordReset(actionCode: string, newPassword: string): Promise<void> {
    return confirmPasswordReset(this.auth, actionCode, newPassword);
  }

  public checkAuthorization(user: LcsUser, allowedRoles: Roles[]): boolean {
    if (!user) return false;

    for (const role of allowedRoles) {
      if (user.roles.includes(role)) {
        return true;
      }
    }
    return false;
  }

  public getEmail(): string{
    return this.auth.currentUser.email;
  }
}
