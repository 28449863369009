import {
  AVAILABLE_EQUIPMENT_BEHAVIORS,
  AVAILABLE_EQUIPMENT_TYPES,
  AVAILABLE_VPN_BEHAVIORS,
  EQUIPMENT_PLAN_OPTIONS
} from "@shared/enums";
import { Timestamp } from "firebase/firestore";
import { MessageModificationMaxTimeOptions } from "./company.model";

export interface EquipmentExtData {
  id: string;
  pasw: string;
  hw_id: string;
  userId?: string;
}

export interface EquipmentDataSource {
  serialNumber: string;
  creationDate: string;
  lastInteractionDate: string;
  partner: string;
  client: string;
  version: string;
};

export interface AdvancedEquipmentFilter {
  type: AVAILABLE_EQUIPMENT_TYPES;
  page?: number;
  limit?: number;
  initialDate?: string;
  finalDate?: string;
  partner?: string;
  company?: string;
  serialNumber?: string;
  behavior?: AVAILABLE_EQUIPMENT_BEHAVIORS;
  isConfigured?: boolean;
  currentList?: Equipment[];
  isBackward?: boolean;
}

export interface SkyvoiceSearchResult {
  skyvoiceList: Equipment[];
  context: any;
}

export interface EquipmentMobiConfig {
  type: EQUIPMENT_PLAN_OPTIONS;
  maxUsersLimit: number;
  collaborationEnabled: boolean;
  recordingRetentionTime: number;
  simultcalls: number;
  messageModificationMaxTime: MessageModificationMaxTimeOptions
}

export interface Equipment {
  id?: string, // Document id
  sn: string, // Serial number
  equip_type: AVAILABLE_EQUIPMENT_TYPES, // Equipment type
  lcs_config: EquipmentMobiConfig, // Configuration
  creationDate: Timestamp, // Creation date
  lastInteractionDate?: Timestamp, // Last provisioning date
  partnerId?: string, // Partner id
  companyId?: string, // Company id
  behavior?: AVAILABLE_EQUIPMENT_BEHAVIORS, // Behavior
  time?: number, // Time used for some behaviors
  lic?: EquipmentLicense, // License
  ver: string; // Version
  resource: Resource[]; // Resources from the equipment
  localnet?: Localnet,  // Info receives from network discovery or that impact provisioning
  vpn_required?: AVAILABLE_VPN_BEHAVIORS; // VPN - VPN data
  webPort?: string;
  sshPort?: string;
  vpnIp?: string;
  ctiPort?: string;
  webuser?: string;
  webpass?: string;
}

export interface EquipmentLicense {
  max_ext: number; // Limit of extensions (negative = unlimited, 0 = default)
  max_voip: number; // Limit of VoIP lines (negative = unlimited, 0 = default)
  max_rec: number; // Limit of recordings (negative = unlimited, 0 = default)
  max_convers: number; // Limit of simultaneous conversations (negative = unlimited, 0 = default)
  intercalesp: number; // If > 0, authorized to do special intercalation
  contaction: number; // Limit of simultaneous instances of contaction
  contactsoft: number; // Limit of simultaneous instances of contaction softphone
  contactop: number; // Limit of simultaneous instances of contaction operator
  ccenter: number; // If >0, defines authorization for call center
  ccentersup: number; // Limit of instances for the call center supervisor software
  ccenterrel: number; // Limit of instances for the call center report
  ccentercrm: number; // If >0, defines authorization for call center CRM
  ccenterdial: number; // If >0, defines authorization for call center dialer
  ccenterpa: number; // Limit of instances for call center service positions
  guestcenter: number; // Limit of instances for the guest center
  protoclext: number; // Limit of instances for the external protocol
  gcmpa: number; // Limit of instances for call positions in GCM
}

export interface Resource {
  type: string; // Type of resource - example: disk
  model: string; // Model of resource - example: 1TB
  detail: string; // Detail of resource - example: Toshiba
}

export interface Localnet {
  dhcp: boolean; // DHCP - how the device acquired network information
  ip: string; // IP - Local address
  mask: string; // Mask - Subnet mask
  gwp: string; // Gateway - Default gateway
  dns: string; // DNS - DNS server
  internet: boolean; // Internet - True if the equipment has internet access
  localdomain: string; // What is currently configured as a domain on the local network - example: skyvoice
  extip: string; // What internet IP did the device detect to be visible on
}
