import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  constructor(private spinner: NgxSpinnerService) {}

  show(type?: string) {
    const name = type ? `loadingSpinner_${type}` : 'loadingSpinner';
    this.spinner.show(name);
  }

  hide(type?: string) {
    const name = type ? `loadingSpinner_${type}` : 'loadingSpinner';
    this.spinner.hide(name);
  }
}
