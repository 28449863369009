import { Component } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CUSTOM_ICONS, PREFIX_STORAGE } from '@shared/constants';
import { AVAILABLE_LANGUAGES } from '@shared/enums';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private auth: Auth,
  ) {
    this.setLanguage();
    this.initCustomIcons();
    this.auth.languageCode = AVAILABLE_LANGUAGES.PT_BR;
  }

  /**
 * Set current language
 */
  private setLanguage(): void {
    // Get language from localstorage
    let language = localStorage.getItem(`${PREFIX_STORAGE}:language`);
    // If not found, get default browser language
    if (!language) { language = this.translate.getBrowserLang(); }
    // Set english as default language
    this.translate.setDefaultLang(AVAILABLE_LANGUAGES.EN);
    // Set current language
    this.translate.use(language);
  }

  private initCustomIcons(): void {
    CUSTOM_ICONS.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }
}
