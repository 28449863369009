import { Injectable } from '@angular/core';
import { CollectionReference, DocumentReference, Firestore, collection, collectionData, doc, getDoc, getDocs, query, updateDoc, where } from '@angular/fire/firestore';
import { CompanyService } from '../company/company.service';
import { Observable, first, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { getExtensionPath, getExtensionPathByCompanyId } from '@shared/utils';
import { EquipmentExtData, PBXExtension } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class ExtensionService {
  public extensions: any[] = [];
  public $extensions: Observable<any[]>;
  public equipId: any;

  constructor(
    private companyService: CompanyService,
    private firestore: Firestore,
    private http: HttpClient
  ) {
  }

  public async getDeviceExtensions(): Promise<any[]> {

    const extensions: any[] = []
    // Get collection ref
    const col = <CollectionReference<any>>(
      query(
        collection(this.firestore, `devices`),
        where('companyId', '==', this.companyService.company.id),
      )
    );

    // Get doc
    const docsSnapshot = await getDocs<any>(col).then(async (res) => {

      // Get companies with the id


      this.equipId = res.docs[0].id;
      const appQuery = query(collection(
        this.firestore, `devices/${res.docs[0].id}/extensions`
      ))

      const ds = await getDocs(appQuery)

      ds.docs.forEach(extesion => {
        extensions.push(extesion.data())
      })

    });

    return extensions
  }

  async createDeviceExtensions(extensions) {
    try {
      // Las extension index
      let lastExtIndex;

      if (extensions) {
        lastExtIndex = extensions.length - 1;

        // Device which have current company id
        const docRef = doc(this.firestore, `devices/${this.equipId}`)


        await getDoc(docRef).then(async (res) => {
          const deviceData = res.data();

          const tobeCreated = deviceData.lic.max_ext

          const url = `${environment.baseURL}/device/createext`;

          // Need this await, because of waiting for http response
          await lastValueFrom(this.http.post<any>(url, {
            sn: this.equipId, //series number
            equipType: 'skyvoice',
            extQuantity: tobeCreated // extension quantity
          }).pipe(first()))

        })
      }
    }
    catch (err) {
      console.log(err)
    }

  }

  async getDeviceExtLimit() {

    // Device which have current company id
    const docRef = doc(this.firestore, `devices/${this.equipId}`)

    const res = await getDoc(docRef);

    const extLimit = res.data().lic.max_ext;

    return extLimit;
  }

  public async getDeviceEquipId() {
    // Get collection ref
    const col = <CollectionReference<any>>(
      query(
        collection(this.firestore, `devices`),
        where('companyId', '==', this.companyService.company.id),
      )
    );

    // Get docs
    const docsSnapshot = await getDocs<any>(col)

    // Update equipId
    this.equipId = docsSnapshot.docs[0].id;

    // Return equipId
    return this.equipId
  }

  /**
 * Update extension
 * @param {string} equipId Equipment ID
 * @param {Partial<EquipmentExtData>} ext Extension to be updated
 */
  public updateDeviceExt(equipId: string, ext: Partial<EquipmentExtData>): Promise<void> {
    // Get path
    const path = getExtensionPath(equipId, ext.id);

    // Get document
    const document = <DocumentReference<any>>(doc(this.firestore, path));

    // Update document
    return updateDoc<EquipmentExtData>(document, ext);
  }

  /**
   * Listen to all extensions from company
   */
  public getExtensions(): Observable<PBXExtension[]> {
    // Get company id
    const companyId = this.companyService.company.id;

    // Get collection ref
    const col = <CollectionReference<PBXExtension>>(
      collection(this.firestore, getExtensionPathByCompanyId(companyId))
    );

    // Get all docs
    return collectionData<PBXExtension>(col, { idField: 'id' }).pipe();
  }
}
