
import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl implements OnDestroy {

  sub: Subscription;

  constructor(private translate: TranslateService) {
    super();

    this.sub = this.translate.onLangChange
      .subscribe(() => {
        this.getAndInitTranslations();
      });

    this.getAndInitTranslations();
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  getAndInitTranslations() {
    this.itemsPerPageLabel = this.translate.instant('paginator.itemsPerPageLabel') + ': ';
    this.firstPageLabel = this.translate.instant('paginator.firstPageLabel');
    this.previousPageLabel = this.translate.instant('paginator.previousPageLabel');
    this.nextPageLabel = this.translate.instant('paginator.nextPageLabel');
    this.lastPageLabel = this.translate.instant('paginator.lastPageLabel');
    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.translate.instant('paginator.of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('paginator.of')} ${length}`;

  }
}
