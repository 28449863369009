import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as fernet from 'fernet';
// var fernet = require('fernet');

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  private key = 'L3uc0.';

  constructor() { }

  /**
   * Encrypt a msg
   * @param {string} data The content that will be encrypted
   * @param {string} [key="L3uc0."] The key that will be use
   * @returns The encrypted data
   */
  encrypt(data: string, key: string = this.key): string {
    return CryptoJS.AES.encrypt(data, key).toString();
  };

  decrypt(data: string, key: string = this.key): string {
    const bytes = CryptoJS.AES.decrypt(data, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  decryptText(data: string, key: string): string {
    try {
      var secret = new fernet.Secret(key);

      var token = new fernet.Token({
        secret: secret,
        token: data,
        ttl: 0
      })
      return token.decode();
    } catch (error) {
      console.log(error);
      return 'erro'
    }
  }
}
