import { HttpParams } from "@angular/common/http";

/**
 * Form the params of the req
 * @returns {HttpParams} Params
 */
export const formParams = (filter: any): HttpParams => {
  let params = new HttpParams();

  // Added the filter data on params
  for (const value in filter) {
    if (filter[value] !== null && filter[value] !== undefined && filter[value] !== '')
      if (Array.isArray(filter[value])) {
        filter[value].forEach((element: string, index: number) => {
          params = params.append(`${value}[${index}]`, element);
        });
      } else {
        params = params.append(value, filter[value]);
      }
  }

  return params;
}
