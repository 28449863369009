export enum BillingStatus {
  ACTIVATION = 'activation',
  DEACTIVATION = 'deactivation',
  VALIDATION = 'validation',
  UPDATE = 'update'
}

export enum BillingCycle {
  monthly = 'monthly',
  quarterly = 'quarterly',
  semester = 'semester',
  yearly = 'yearly'
}
