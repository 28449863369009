import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getCountFromServer,
  limit,
  orderBy,
  Query,
  query,
  updateDoc,
  endAt,
  where,
  startAfter,
  getDoc
} from '@angular/fire/firestore';
import { AdvancedEquipmentFilter, Equipment } from '@shared/models';
import { getEquipmentPath } from '@shared/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  constructor(private firestore: Firestore) { }

  /**
   * Count equipments
   * @param {AdvancedEquipmentFilter} filter
   * @returns {Promise<number>} Count of equipments
   */
  public async countEquipments(filter: AdvancedEquipmentFilter): Promise<number> {
    // Init query
    const q = this.formInitQuery(filter, false);

    // Get count of documents
    const snapshot = await getCountFromServer(q);

    // Return count
    return snapshot.data().count;
  }

  /**
   * Get equipment by id
   * @param {string} id Equipment id
   */
  public async getById(id: string): Promise<Equipment> {
    // Get path
    const path = getEquipmentPath(id);

    // Get document
    const document = <DocumentReference<Equipment>>(doc(this.firestore, path));

    // Get document snapshot
    const snapshot = await getDoc(document);

    // Return document data
    return { id: snapshot.id, ...snapshot.data() };
  }

  /**
   * Update skyvoice
   * @param {Equipment} data Skyvoice data
   */
  public update(data: Equipment): Promise<void> {
    // Get path
    const path = getEquipmentPath(data.id);

    // Get document
    const document = <DocumentReference<Equipment>>(doc(this.firestore, path));

    // // Update document
    return updateDoc(document, {
      behavior: data.behavior,
      time: data.time,
      companyId: data.companyId,
      partnerId: data.partnerId,
      lic: data.lic,
      lcs_config: data.lcs_config,
      vpn_required: data.vpn_required,
    });
  }

  /**
   * Delete skyvoice
   * @param {string} id Skyvoice id
   */
  public async delete(id: string): Promise<void> {
    // Get path
    const path = getEquipmentPath(id);

    // Get document
    const docRef = doc(this.firestore, path);

    // Delete document
    await deleteDoc(docRef);
  }

  /**
   * List the Skyvoice
   */
  public list(filter: AdvancedEquipmentFilter): Observable<Equipment[]> {
    // Init query
    const q = this.formInitQuery(filter);

    // Get equipment collection
    const colRef = <CollectionReference<Equipment>>q;

    // Return collection data
    return collectionData<Equipment>(colRef, { idField: 'id' });
  }

  /**
   * Form init query
   * @param {AdvancedEquipmentFilter} filter Filter
   * @param {boolean} withPagination With pagination
   * @returns {Query<DocumentData>} Query
   */
  private formInitQuery(filter: AdvancedEquipmentFilter, withPagination: boolean = true): Query<DocumentData> {
    // Get path
    const path = getEquipmentPath()

    // Init query
    let q = query(
      collection(this.firestore, path),
      orderBy("companyId"), // Order by company id
      orderBy("creationDate", "desc"), // Order by create date
      where('equip_type', '==', filter.type), // Filter by type
    )

    // if serial number is defined, add where clause
    if (!!filter.serialNumber) q = query(q, where('sn', '==', filter.serialNumber));

    // if partner is defined, add where clause
    if (!!filter.partner) q = query(q, where('partnerId', '==', filter.partner));

    // if is configured page, add where clause
    if (filter.isConfigured) {
      if (!!filter.company) q = query(q, where('companyId', '==', filter.company));  // if company is defined, add where clause
      else q = query(q, where('companyId', '!=', '')); // Company id is not empty
    } else q = query(q, where('companyId', '==', '')); // Company id is empty

    // if behavior is defined, add where clause
    if (!!filter.behavior) q = query(q, where('behavior', '==', filter.behavior));

    // if pagination is enabled, add pagination clauses
    if (withPagination) {
      // Get last element from current list
      const lastElement = filter.currentList[filter.currentList.length - 1];

      // if last element is defined, add pagination clauses
      if (lastElement && filter.page > 0) {
        q = filter.isBackward
          ? query(q, endAt(lastElement.companyId, lastElement.creationDate)) // End at the page
          : query(q, startAfter(lastElement.companyId, lastElement.creationDate)); // Start at the page
      }

      // Limit the results
      q = query(q, limit(filter.limit));
    }

    // Return query
    return q;
  }
}
