import { Injectable } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  collection,
  collectionData,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
} from '@angular/fire/firestore';
import { Company, Group } from '@shared/models';
import { getGroupsPath } from '@shared/utils/create-path';
import { first, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  private groups: Group[] = [];

  constructor(
    private firestore: Firestore,
  ) { }

  /**
   * Set the Group
   * @param {Group[]} Group All company Group
   */
  public setGroups(groups: Group[]): void {
    this.groups = groups
  }

  /**
   * Get the Group
   * @returns {Group[]} All company Group
   */
  public getGroups(): Group[] {
    return this.groups
  }

  /**
   * Get groups
   * @param {string} id tenant ID
   * @returns All groups that belong to that company
   */
  public fetchAll(id: string): Observable<Group[]> {
    const col = <CollectionReference<Group>>(
      collection(this.firestore, getGroupsPath(id))
    );

    return collectionData<Group>(col, { idField: 'id' }).pipe(tap((groups) => this.setGroups(groups)));
  }

  /**
   * Create a new group
   * @param {Company} company The company that is the owner of the group
   * @param {Group} group Obj that contains the group data
   */
  public async create(company: Company, group: Group): Promise<string> {
    const colRef = collection(this.firestore, getGroupsPath(company.id));
    const newDoc = await addDoc(colRef, {
      companyId: company.id,
      name: group.name,
      status: group.status,
    });

    return newDoc.id;
  }

  /**
   * Create new groups from import
   * @param {Company} company The company that is the owner
   * @param {string[]} groupNames Name of the new groups
   * @returns {Group[]} An array of the new groups
   */
  public async importGroups(company: Company, groupNames: string[]): Promise<Group[]> {
    return new Promise(async (resolve, reject) => {
      const groups: Group[] = [];
      try {
        for (const name of groupNames) {
          const colRef = collection(this.firestore, getGroupsPath(company.id));
          const newDoc = await addDoc(colRef, {
            companyId: company.id,
            name: name,
            status: true,
          });

          groups.push({
            id: newDoc.id,
            name: name,
            status: true,
          })
        }
        resolve(groups);
      } catch (error) {
        resolve([]);
      }
    });
  }

  /**
   * Delete a group
   * @param {Company} company The company that is the owner of the group
   * @param {Group} group The group that will be removed
   */
  public async deleteGroup(company: Company, group: Group): Promise<void> {
    const docRef = doc(this.firestore, getGroupsPath(company.id, group.id));
    await deleteDoc(docRef);
  }

  /**
   * Delete a list of groups
   * @param {Company} company The company that is the owner of the group
   * @param {Group[]} groups an array of groups
   */
  public async deleteGroups(company: Company, groups: Group[]): Promise<void> {
    return new Promise(async (resolve, reject) => {
      for (const group of groups) {
        await this.deleteGroup(company, group)
      }
      resolve();
    });
  }

  /**
   * Update a group
   * @param {Company} company The company that is the owner of the group
   * @param {Group} newGroupData New Data
   * @returns The new group
   */
  public update(company: Company, newGroupData: Group): Promise<void> {
    const docRef = doc(this.firestore, getGroupsPath(company.id, newGroupData.id));
    return updateDoc(docRef, {
      companyId: company.id,
      name: newGroupData.name,
      status: newGroupData.status,
    });
  }

  /**
    * Parse the id to name
    * @param {Group[]} allGroups All groups
    * @param {string[]} groupIds Id list
    * @returns {string[]} All the group names
    */
  public idToName(allGroups: Group[], groupIds: string[]): string[] {
    const groups: Group[] = allGroups.filter(value => groupIds.includes(value.id));
    return groups.map(value => value.name);
  }


  /**
 * Create a new group if not exist
 * @param {string[]} tags Integration
 * @param {Company} company Company | Tenant
 * @returns {Promise<string[]>} New tags ids
 */
  public async parseTagNameToId(tags: string[], company: Company): Promise<string[]> {
    // Get groups ids
    const tagIds = this.groups.map(value => value.id);

    // For each tag create a new tag if not exist
    for (let index = 0; index < tags.length; index++) {
      const tag = tags[index];
      if (!tagIds.includes(tag)) {
        const id = await this.create(company, {
          name: tag.toUpperCase().trim(),
          status: true
        });
        tags[index] = id;
      }
    }

    // Return new tags
    return tags;
  }
}
