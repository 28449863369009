export enum AVAILABLE_LANGUAGES { EN = 'en', ES = 'es', PT_BR = 'pt' };

export enum AVAILABLE_TIMEZONE_NAME {
  SAO_PAULO = 'America/Sao_Paulo',
  BELEM = 'America/Belem',
  MANAUS = 'America/Manaus',
  RIO_BRANCO = 'America/Rio_Branco',
  NORONHA = 'America/Noronha',
  MÉXICO_CITY = 'America/Mexico_City',
  TIJUANA = 'America/Tijuana',
  BAJA_SUR = 'Mexico/BajaSur',
};
