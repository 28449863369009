export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBWfWtTW1YRG-P5pEyjbZhgbVgPDoH-i10',
    authDomain: 'lcs-dev-940.firebaseapp.com',
    databaseURL: 'https://lcs-dev-940-default-rtdb.firebaseio.com',
    projectId: 'lcs-dev-940',
    storageBucket: 'lcs-dev-940.appspot.com',
    messagingSenderId: '980648121603',
    appId: '1:980648121603:web:cb6552dd5b94c6bf9cc536',
    measurementId: 'G-QCZW7K8HWB',
  },
  baseURL: 'https://us-central1-lcs-dev-940.cloudfunctions.net',
  salesEmail: ['vapiw15277@eilnews.com', 'lemep14794@oprevolt.com'],
  middlewareBaseUrl: `${location.protocol}//<ip>:9041/cluster/api/v1/domains`,
  multiTalkBaseUrl: 'https://lcs-services-dev.leucotron.com.br:9060',
  version: require("../../package.json").version,
  mobiOfficialUrl: 'https://mobi-des.leucotron.io',
  mobiIframeUrl: 'https://lcs-dev-940.firebaseapp.com',
  matrixUrl: 'https://lcs-services-dev.leucotron.com.br',
  matrixAdminUser: 'lcs-collaboration-dev',
  matrixAdminPassword: 'collaboration-dev@L3uc0.',
  socketUrl: 'https://lcs-services-dev.leucotron.com.br:9049',
  grafanaUrl: 'https://lcs-services-dev.leucotron.com.br:3300',
  aiApiURL: 'https://lcs-services-dev.leucotron.com.br:9062',
  criptokey:'LrtsttveOWCy9ampslsbHUqhv-u3VS2jqONEcIWmz2w='
};
