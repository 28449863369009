export const DOMINE_REGEX =
  /^(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$|^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

export const HTTPS_REGEX =
  /^(https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const COMPANY_NAME_REGEX = /^(?:([a-z]|[A-Z]).*)$/;

export const PHONE_NUMBER = /^\d{10,14}$/;

export const PROTOCOL_REGEX = /^(\d){1,20}\.?\d{1,10}$/;

export const NUMBER_REGEX = /^\d+$/;

export const CPF_CNPJ_REGEX = /(^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$)|(^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2})$/;

export const CNPJ_REGEX = /(^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2})$/;

export const EMAIL_DOMAIN =
  /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const EXTERNAL_ROUTE_REGEX = /^[\d\#?\*?]{0,3}$/;

export const TRANSFER_CODE_REGEX = /^[\d\#?\*?]{0,}$/;

export const DEVICE_EXT_PASSWORD_REGEX = /^[a-zA-Z0-9_\-\.!]{8,31}$/;

export const MAC_ADDRESS_OR_PORT_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-5][0-5][0-3][0-5])(\/([1-9]|[1-9][0-9]{1,3}|[1-3][0-9]{4}|40[0-8][0-9]|409[0-5]))?$/;

// No empty spaces, no special characters, no accents and only lowercase letters and numbers - 4 to 20 characters
export const COLLABORATION_SERVER_NAME_REGEX = /^[a-z0-9]{4,20}$/;

// External ip address or domain regex
export const EXTERNAL_IP_ADDRESS_OR_DOMAIN_REGEX = /^(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$|^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
