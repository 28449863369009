export interface User {
  id?: string;
  displayName: string;
  email: string;
  passwordExten: string;
  extension: string;
  photoUrl?: string;
  status?: string;
  groups?: string[];
  tag?: string;
  needToSync?: boolean;
  enableMultiCall?: boolean;
  receive_call_while?: {
    Busy: boolean;
    DoNotDisturb: boolean;
    BeRightBack: boolean;
    Away: boolean;
  };
  disabled: boolean;
  domainPbx?: string;
  portSipPbx?: string;
  pbxId?: string;
  multiTalkUserId?: number;
  multiTalkAuthorizationUser?: string;
  authuser?: string;
  pbxRecEnabled?: boolean;
  areaCode?: string;
  dialplanId?: string;
  userType?: string;
}


export enum Roles {
  LEUCOTRON = 'leucotron',
  ADMIN = 'admin',
  PARTNER = 'partner',
  USER = 'user',
}

export interface LcsUser {
  uid?: string;
  email: string;
  roles: Roles[];
  partnerId: string;
  displayName?: string;
  photoURL?: string;
}
