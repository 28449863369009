
export interface Cluster {
  id: string;
  name: string;
  domain: string;
  ipAddress: string;
  port: string;
  active: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  vpnPort?: string;
  vpnCredFile?: string | File;
}
