import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_DATE_FORMAT } from '@shared/constants';
import { AVAILABLE_LANGUAGES } from '@shared/enums';
import { Company, Partner, User } from '@shared/models';
import { getCompanyPlanType } from '@shared/utils';
import { CompanyEmailData, PartnerEmailData } from '../email/utils/email.model';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe
  ) { }

  public toFindDuplicates(arrayOfValue: string[]): string[] {
    return arrayOfValue.filter(
      (item, index) => arrayOfValue.indexOf(item) !== index
    );
  }

  public getBrowserName(): string {
    const agent: string = window.navigator.userAgent;
    switch (true) {
      case /edge/i.test(agent):
        return 'Microsoft Edge';
      case /edg/i.test(agent):
        return 'Microsoft Edge (Chromium)';
      case /opr/i.test(agent):
        return 'Opera';
      case /chrome/i.test(agent):
        return 'Google Chrome';
      case /safari/i.test(agent):
        return 'Safari';
      case /firefox/i.test(agent):
        return 'Mozilla Firefox';
      case /msie/i.test(agent):
        return 'Internet Explorer';
      case /ucbrowser/i.test(agent):
        return 'UC Browser';
      case /yabrowser/i.test(agent):
        return 'Yandex';
      default:
        return 'Unknown';
    }
  }

  sortObjectArrayData(
    array: any[],
    field: string,
    secondaryFields?: string[]
  ): any[] {
    return array.sort((a, b) => {
      let sortValue: number = this.setData(a, b, field);
      if (sortValue === 0 && secondaryFields) {
        for (const [i, f] of secondaryFields.entries()) {
          sortValue = this.setData(a, b, f);
          if (sortValue !== 0) {
            return sortValue;
          }
        }
      }
      return sortValue;
    });
  }

  private setData(a: any, b: any, field: string): number {
    let normalizeA: any = a[field];
    let normalizeB: any = b[field];
    if (!normalizeA || !normalizeB) {
      return 0;
    }
    if (typeof a[field] === 'string') {
      normalizeA = normalizeA
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      normalizeB = normalizeB
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    }
    if (normalizeA > normalizeB) {
      return 1;
    } else if (normalizeA < normalizeB) {
      return -1;
    }
    return 0;
  }

  usersToString(users: User[]): string {
    let usersString: string = '[';
    users.forEach((user) => {
      usersString += JSON.stringify(user);
    });
    return `${usersString}]`;
  }

  stringToUser(users: string | null): User[] {
    if (users === null || users === '' || users === '[]') {
      return [];
    }

    const usersArray: User[] = [];
    users = users.substring(1, users.length - 1);
    const splittedUsers: string[] = users.split('}{');
    splittedUsers.forEach((user, index) => {
      if (splittedUsers.length > 1) {
        if (index === 0) {
          usersArray.push(JSON.parse(`${user}}`));
        } else if (index === splittedUsers.length - 1) {
          usersArray.push(JSON.parse(`{${user}`));
        } else {
          usersArray.push(JSON.parse(`{${user}}`));
        }
      } else {
        usersArray.push(JSON.parse(user));
      }
    });
    return usersArray;
  }

  /**
   * Filter Data between interval
   * @param {T[]} data Data values
   * @param {string} field Data values
   * @param {Date} start Start date
   * @param {Date} end End date
   * @param {boolean} withoutTimezone True if the date in Data is in no timezone
   * @returns {T[]} All data between interval
   */
  public getDataBetweenDates<T>(
    data: T[],
    field: string,
    start: Date,
    end: Date,
    withoutTimezone: boolean = true
  ): T[] {
    return data.filter(item => {
      const dateValue = item[field];
      const date = withoutTimezone
        ? new Date(this.getDateInTimeZone(dateValue))
        : new Date(dateValue)
      return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
    });
  }

  /**
   * Format firebase date to local date
   * @param {any} date Pure date
   * @returns {string} Date in local lang
   */
  public formatFirebaseDateToLocalDate(date: any): string {
    // If not exist
    if (!date) return this.translate.instant('no');

    // Get locate lang
    const localeLang = this.getDateFormatByLocale();

    // If is a string
    return typeof date == 'string'
      ? this.datePipe.transform(date, localeLang)
      : this.datePipe.transform(date.toDate(), localeLang)
  }

  /**
   * Form Company Email Data
   * @param {Company} company Company data
   * @param {Partner[]} partners All partners
   * @returns {CompanyEmailData} Company Email Data
   */
  public formCompanyEmailData(company: Company, partners: Partner[]): CompanyEmailData {
    return {
      company: company.name,
      partner: partners.find(value => value.id == company.partner)?.name ?? 'Leucotron',
      email: company.email !== '' ? company.email : this.translate.instant('no'),
      cnpj: company.cnpj,
      maxUsersLimit: company.maxUsersLimit,
      billingCycle: this.translate.instant(company.billingCycle ?? 'no'),
      simultcalls: company.simultcalls,
      retentionTime: company.recordingRetentionTime,
      registrationDate: this.formatFirebaseDateToLocalDate(company.createdAt ?? new Date().toString()),
      expirationDate: this.formatFirebaseDateToLocalDate(company.trialTime),
      modality: this.translate.instant(getCompanyPlanType(company).text),
    }
  }

  /**
   * Form Partner Email Data
   * @param {Partner} partner Partner data
   * @returns {PartnerEmailData} Partner Email Data
   */
  public formPartnerEmailData(partner: Partner): PartnerEmailData {
    return {
      name: partner.name,
      adminEmail: partner.email,
      cnpj: partner.cnpj,
      simultcalls: partner.simultCallsLimit
    }
  }

  /**
   * Remove empty values
   * @param {any} value Obj that will be modify
   * @returns {T} Obj with no empty or null values
   */
  public removeEmptyValues<T>(value: any): T {
    const obj = { ...value };
    for (const key in obj) {
      if (!obj[key]) delete obj[key];
    }
    return obj;
  }

  /**
   * Get a new Attr value if exist
   * @param {string} attr Attr that will be parse
   * @param {{ key: string, value: string }[]} options All options of parse
   * @returns {string} New attr value
   */
  public parseAttr(
    attr: string,
    options: { key: string, value: string }[]): string {
    return options.find(type => type.key === attr)?.value ?? attr;
  }

  /**
   * Download file to user's computer
   * @param {Blob | string} path Blob data or URL
   * @param {string} fileName File Name
   */
  public async downloadFile(path: Blob | string, fileName: string) {
    try {
      // Form blob
      const blob = path instanceof Blob
        ? path
        : await fetch(path).then(resp => resp.blob());

      // Creates a DOMString containing a URL representing the object given in the parameter.
      const url = window.URL.createObjectURL(blob);

      // Create element 'a' to download from link
      const a = document.createElement('a');

      // Hide element
      a.style.display = 'none';

      // Set href
      a.href = url;

      // Create download option
      a.download = fileName;

      // Add the element to the document page
      document.body.appendChild(a);

      // Execute the click to download the file
      a.click();

      // Remove elements and references
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a)

    } catch (error) {
      throw new Error('Fails on fetch file')
    }
  }

  /**
   * Parse a date without timezone to one with a specific time zone
   * @param {string} date Date value
   * @param {string} timeZone String that contains the timezone
   * @returns Date in the new timezone
   */
  public getDateInTimeZone(
    date: string,
    timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
  ): string {
    return date && new Date(`${date.replace(/-/g, '/')} GMT-0000`)
      .toLocaleString("en", { timeZone });
  }

  /**
   * Remove timezone
   * @param {string} date Date with time zone value
   * @returns Date without timezone
   */
  public getDateWithoutTimeZone(date: string | Date): string {
    if (!date) return null;
    const currentDate = date instanceof Date ? date : new Date(date);
    const userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
    const newDate = new Date(currentDate.getTime() + userTimezoneOffset);
    return this.datePipe.transform(newDate, DEFAULT_DATE_FORMAT)
  }

  /**
   * Get date format by language
   * @returns {string} Date format
   */
  public getDateFormatByLocale(onlyMonth: boolean = false): string {
    switch (this.translate.currentLang) {
      // English
      case AVAILABLE_LANGUAGES.EN:
        return onlyMonth
          ? 'M/yyyy'
          : 'M/d/yyyy HH:mm';

      // Spanish
      case AVAILABLE_LANGUAGES.ES:
        return onlyMonth
          ? 'M/yyyy'
          : 'd/M/yyyy HH:mm';

      // Brazilian portuguese
      default:
        return onlyMonth
          ? 'MM/yyyy'
          : 'dd/MM/yyyy HH:mm';
    }
  }

  /**
   * Get date format by language
   * @returns {string} Date format
   */
  public getChartDateFormatByLocale(): string {
    switch (this.translate.currentLang) {
      // English
      case AVAILABLE_LANGUAGES.EN:
        return 'M/d';

      // Spanish
      case AVAILABLE_LANGUAGES.ES:
        return 'd/M';

      // Brazilian portuguese
      default:
        return 'dd/MM';
    }
  }

  /**
   * Sort array by date
   * @param {T[]} array Array that will be sorted
   * @param {string} field Field that contains the date
   * @param {'DESC' | 'ASC'} type Sort type
   * @returns {T[]} Sorted array
   */
  public sortArrayByDate<T>(array: T[], field: string, type: 'DESC' | 'ASC' = 'ASC'): T[] {
    const sortedArray = array
      .sort((a, b) => new Date(b[field]).getTime() - new Date(a[field]).getTime())
    return type == 'ASC' ? sortedArray : sortedArray.reverse()
  }

  /**
   * Compare arrays to verify if they have the same value
   * @param {T[]} array1 Fist array
   * @param {T[]} array2 Second array
   * @returns {boolean}
   */
  public areArraysTheSame<T>(array1: T[], array2: T[]): boolean {
    if (array1.length === array2.length) {
      return array1.every(element => array2.includes(element));
    } else {
      return false;
    }
  }
}
