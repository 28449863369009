export interface CompanyEmailData {
  company?: string;
  partner?: string;
  email?: string;
  cnpj?: string;
  maxUsersLimit?: number;
  billingCycle?: string;
  simultcalls?: number;
  retentionTime?: number;
  registrationDate?: string;
  expirationDate?: string;
  modality?: string;
}

export interface PartnerEmailData {
  name?: string;
  adminEmail?: string;
  cnpj?: string;
  simultcalls?: number
}
