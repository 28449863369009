import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  Firestore,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  documentId,
  getDocs,
  DocumentData,
  setDoc,
} from '@angular/fire/firestore';
import { environment } from '@environments/environment';
import { Partner, PartnerRanking } from '@shared/models';
import { formParams, getPartnerFunctionsPath, getPartnerPath } from '@shared/utils';
import { first, lastValueFrom, Observable } from 'rxjs';
import { EmailService } from '../email/email.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  public $partners: Observable<Partner[]>;
  private url: string = `${environment.baseURL}/lcs/user`;

  constructor(
    private firestore: Firestore,
    private http: HttpClient,
    private emailService: EmailService
  ) {
    this.fetchAll();
  }

  /**
   * Create the obj with the firestore data
   * @param {Partner} partner Partner data
   * @returns {DocumentData} Partner data in format of a firestore doc data
   */
  private formFirestoreData(partner: Partner): DocumentData {
    return {
      name: partner.name,
      email: partner.email,
      clusterId: partner.clusterId,
      collaborationClusterId: partner.collaborationClusterId,
      simultCallsLimit: partner.simultCallsLimit,
      usedSimultCalls: partner.usedSimultCalls,
      type: partner.type,
      cnpj: partner.cnpj,
      language: partner.language,
      hasMultiTalk: partner?.hasMultiTalk ?? false,
    };
  }

  /**
   * Get all partners
   * @returns All partners
   */
  private fetchAll(): void {
    const col = <CollectionReference<Partner>>(
      collection(this.firestore, getPartnerPath())
    );
    this.$partners = collectionData<Partner>(col, { idField: 'id' });
  }

  /**
   * Get a specific partner by its id
   * @param {string} id Partner id
   * @returns {Promise<Partner>} Partner
   */
  public async getPartner(id: string): Promise<Partner> {
    const col = query(
      collection(this.firestore, getPartnerPath()),
      where(documentId(), '==', id)
    );
    const querySnapshot = await getDocs(col);
    const doc = querySnapshot.docs[0];
    return {
      ...doc.data(),
      id: doc.id,
    } as Partner;
  }

  /**
   * Get if the logged user is a leucotron partner or administrator
   * @param {string} uid Logged user uid
   * @returns {Promise<boolean>} True if is a partner. False if is a Leucotron Administrator
   */
  public async isPartner(uid: string): Promise<boolean> {
    const col = query(
      collection(this.firestore, getPartnerPath()),
      where(documentId(), '==', uid)
    );
    const querySnapshot = await getDocs(col);
    const doc = querySnapshot.docs[0];

    return doc ? doc.exists() ?? false : false;
  }

  /**
   * Get if the logged user is a leucotron partner or administrator
   * @param {string} uid Logged user uid
   * @returns {Observable<PartnerCounters[]>} Partner counters data
   */
  public getRanking(
    date: string,
    resource: 'companies' | 'users',
    limit: number = 10,
  ): Observable<PartnerRanking> {
    // Get path
    const path = getPartnerFunctionsPath() + '/ranking';

    // Filter data
    const filter = { date, limit, resource };

    // Return the tickets
    return this.http.get<PartnerRanking>(path, { params: formParams(filter) });
  }

  /**
   * Create a new partner
   * @param {Partner} partner Obj that contains the partner data
   * @returns Partner ID
   */
  public async create(partner: Partner): Promise<any> {
    const user = await lastValueFrom(
      this.http
        .post<any>(`${this.url}/create`, {
          email: partner.email,
        })
        .pipe(first())
    ).catch((error) => {
      throw error;
    });

    const docRef = doc(this.firestore, getPartnerPath(user.uid));
    await setDoc(docRef, this.formFirestoreData(partner), {
      merge: true,
    });

    return user.uid;
  }

  /**
   * Delete a partner
   * @param {string} id Partner id
   */
  public async remove(id: string): Promise<void> {
    await lastValueFrom(
      this.http
        .delete<any>(`${this.url}/delete`, {
          body: {
            uid: id,
          },
        })
        .pipe(first())
    ).catch((error) => {
      throw error;
    });

    const docRef = doc(this.firestore, getPartnerPath(id));
    await deleteDoc(docRef);
  }

  /**
   * Update a partner
   * @param {Partner} newData New Data
   * @returns The new partner
   */
  public async update(newData: Partner, emailChanged: boolean): Promise<void> {
    if (emailChanged) {
      await lastValueFrom(
        this.http.put<any>(`${this.url}/update`, {
          uid: newData.id,
          newEmail: newData.email,
        })
      ).catch((error) => {
        throw error;
      });

      this.emailService.sendNewPartnerEmail(newData);
    }

    const docRef = doc(this.firestore, getPartnerPath(newData.id));
    return updateDoc(docRef, this.formFirestoreData(newData));
  }
}
