/**
 * Compare two objects
 * @param {any} obj1 Object 1
 * @param {any} obj2 Object 2
 * @returns {boolean} True if both are equal
 */
export const compareObjects = (obj1: any, obj2: any): boolean => {
    // Check if one is undefined
    if (!obj1 || !obj2) return false;
  
    // Check if both are objects
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

/**
 * Compare two arrays
 * @param {any[]} arr1 Array 1
 * @param {any[]} arr2 Array 2
 * @returns {boolean} True if both are equal
 */
export const compareArrays = (arr1: any[], arr2: any[]): boolean => {
    // Check if one is undefined
    if (!arr1 || !arr2) return false;
  
    // Check if has different length
    if (arr1.length !== arr2.length) return false;
  
    // Check if both are objects and compare properties
    const hasNew = Object.keys(arr1).some((x) => !Object.keys(arr2).some((y) => compareObjects(x, y)))
    const hasOld = Object.keys(arr2).some((x) => !Object.keys(arr1).some((y) => compareObjects(x, y)))
  
    // Return if both are equal
    return !hasNew && !hasOld;
  };

type Difference = { [key: string]: { old: any; new: any; }; };

/**
 * Get difference between objects
 * @param {T} newObject Object 1
 * @param {T} originalObject Object 2
 * @param {string[]} privateAttr Private attributes
 * @returns {Difference[]} Difference between objects
 */
export const getDiffBetweenObj = <T>(
    newObject: T,
    originalObject: T,
    privateAttr: string[] = []
  ): Difference[] => {
    // Init difference
    const diff: Difference[] = [];
  
    Object.keys(newObject).forEach((key) => {
      // Get values
      const newVal = newObject[key];
      const oldVal = originalObject[key];
  
      switch (true) {
        // Ignore
        case privateAttr.includes(key): // Private attribute
        case !newVal && !oldVal: // Both are empty
          break;
  
        // Different arrays
        case Array.isArray(newVal) && Array.isArray(oldVal) && !compareArrays(newVal, oldVal):
          diff.push({ [key]: { old: oldVal, new: newVal } });
          break;
  
        // Different values
        case !compareObjects(newVal, oldVal):
          diff.push({ [key]: { old: oldVal, new: newVal } });
          break;
      }
    });
  
    // Return difference
    return diff;
  };