import { TICKETS_STATUS_ENUM, TICKETS_TYPE_ENUM } from "@shared/enums";

export const MAX_SELECTED_DOWNLOADS = 10;

export const TICKETS_TYPE = [
  { key: TICKETS_TYPE_ENUM.received, value: 'received' },
  { key: TICKETS_TYPE_ENUM.inbound, value: 'internal' },
  { key: TICKETS_TYPE_ENUM.outbound, value: 'originated' },
];

export const TICKETS_STATUS = [
  { key: TICKETS_STATUS_ENUM.ANSWERED, value: 'disposition.answered' },
  { key: TICKETS_STATUS_ENUM.NO_ANSWER, value: 'disposition.noAnswer' },
  { key: TICKETS_STATUS_ENUM.FAILED, value: 'disposition.failed' },
  { key: TICKETS_STATUS_ENUM.BUSY, value: 'disposition.busy' },
  { key: TICKETS_STATUS_ENUM.UNKNOWN, value: 'disposition.unknown' },
];

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-dd HH:mm:ss';
