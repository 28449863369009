import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToMinutes',
})
export class NumberToMinutesPipe implements PipeTransform {
  transform(value: number): string {
    if (value <= 0) {
      return '--:--';
    }

    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value - hours * 3600) / 60);
    const seconds: number = value - minutes * 60 - hours * 3600;
    let stgBuilder = '';

    if (hours > 0 && hours < 10) {
      stgBuilder = '0' + hours + ':';
    } else if (hours > 0) {
      stgBuilder += hours + ':';
    }

    if (minutes < 10) {
      stgBuilder += '0' + minutes + ':';
    } else {
      stgBuilder += minutes + ':';
    }

    if (seconds < 10) {
      stgBuilder += '0' + seconds;
    } else {
      stgBuilder += seconds;
    }

    return stgBuilder;
  }
}
