import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { MATRIX_PASS_CLIENT_SUFFIX } from '@shared/constants';
import { createClient, IAuthData, MatrixClient } from 'matrix-js-sdk';
import { ISynapseAdminDeactivateResponse } from 'matrix-js-sdk/lib/@types/synapse';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {
  private matrixClient: MatrixClient;

  constructor() {
    this.login(environment.matrixAdminUser, environment.matrixAdminPassword)
  }

  /**
   * Login
   * @param {string} username Username
   * @param {string} password Password
   */
  private async login(username: string, password: string): Promise<void> {

    // Form identifier
    const identifier = {
      type: 'm.id.user',
      user: username,
    };

    // Create client
    this.matrixClient = createClient({
      baseUrl: environment.matrixUrl,
    });

    // Login credentials
    const loginCredentials = {
      identifier,
      password
    }

    await this.matrixClient.login('m.login.password', loginCredentials);
  }

  /**
   * Register new user
   * @param {string} username Username
   * @returns {Promise<IAuthData>} Auth data
   */
  async register(username: string): Promise<IAuthData> {
    username = username.toLocaleLowerCase();
    const password = username + MATRIX_PASS_CLIENT_SUFFIX;
    return this.matrixClient.registerRequest({ username, password, auth: { type: 'm.login.dummy' } });
  }

  /**
   * Deactivate user
   * @param {string} userId User ID, e.g. @username:matrix.example.com
   * @returns {Promise<ISynapseAdminDeactivateResponse>} Response
   */
  async deactivateSynapseUser(userId: string): Promise<ISynapseAdminDeactivateResponse> {
    return this.matrixClient.deactivateSynapseUser(userId);
  }

}
