export enum AVAILABLE_EQUIPMENT_TYPES {
  Skyvoice = 'skyvoice',
}

export enum AVAILABLE_EQUIPMENT_BEHAVIORS {
  IGNORE = 'ignore',
  PROVIS = 'provis',
  DELAY = 'delay',
  BLOCK = 'block',
  UPDATE = 'update',
}

export enum AVAILABLE_VPN_BEHAVIORS {
  START = 'start',
  STOP = 'stop',
  RELOAD = 'reload',
}

export enum EQUIPMENT_PLAN_OPTIONS {
  none = 'none',
  mobi_telephony = 'mobi_telephony',
  mobi_collaboration = 'mobi_collaboration',
}


