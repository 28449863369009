import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { AuthService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class IsPartnerResolver implements Resolve<void> {
  constructor(
    private authService: AuthService,
  ) { }

  public async resolve(): Promise<void> {
    await this.authService.verifyIfItIsPartner()
  }
}
