import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc, documentId, Firestore, getDocs, query, serverTimestamp,
  setDoc,
  updateDoc, where
} from '@angular/fire/firestore';
import { Cluster } from '@shared/models';
import { getClusterPath } from '@shared/utils/create-path';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  constructor(private firestore: Firestore) { }

  getClusters(): Observable<Cluster[]> {
    const colRef = <CollectionReference<Cluster>>(
      collection(this.firestore, getClusterPath())
    );
    return collectionData<Cluster>(colRef, { idField: 'id' });
  }

  async getById(id: string): Promise<Cluster> {
    const colRef = <CollectionReference<Cluster>>(
      query(
        collection(this.firestore, getClusterPath()),
        where(documentId(), '==', id)
      )
    );
    const querySnapshot = await getDocs<Cluster>(colRef);

    if (!querySnapshot.empty) {
      return { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
    }
    return null;
  }

  create(cluster: Cluster): Promise<void> {
    const docRef = doc(this.firestore, getClusterPath(cluster.id));
    return setDoc(docRef, {
      name: cluster.name,
      domain: cluster.domain,
      ipAddress: cluster.ipAddress,
      port: cluster.port,
      active: cluster.active,
      createdAt: serverTimestamp(),
      vpnCredFile: cluster.vpnCredFile,
    });
  }

  update(cluster: Cluster): Promise<any> {
    const docRef = doc(this.firestore, getClusterPath(cluster.id));
    return updateDoc(docRef, {
      name: cluster.name,
      domain: cluster.domain,
      ipAddress: cluster.ipAddress,
      port: cluster.port,
      active: cluster.active,
      updatedAt: serverTimestamp(),
      vpnCredFile: cluster.vpnCredFile,
    });
  }

  delete(id: string): Promise<void> {
    const docRef = doc(this.firestore, getClusterPath(id));
    return deleteDoc(docRef);
  }
}
