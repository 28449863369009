export interface PageTab {
  label: string;
  path: string;
  index: number;
  partnerCannotSee?: boolean;
}

export const CompanyNavTabs: PageTab[] = [
  // {
  //   label: 'tag.plural',
  //   path: 'tags',
  //   index: 1
  // },
  {
    label: 'user.plural',
    path: 'users',
    index: 2,
  },
  {
    label: 'PABX',
    path: 'pabx',
    index: 3,
  },
  {
    label: 'apps',
    path: 'integrations',
    index: 4,
  },
];

export const SettingsNavTabs: PageTab[] = [
  {
    label: 'user.plural',
    path: 'users',
    index: 0,
  },
];

export const BillingsNavTabs: PageTab[] = [
  {
    label: 'details',
    path: 'details',
    index: 0,
  },
];

export const EquipmentsNavTabs: PageTab[] = [
  {
    label: 'notconfigured',
    path: 'not-configured',
    index: 0,
  },
  {
    label: 'configured',
    path: 'configured',
    index: 1,
  },
];

export const DashNavTabs: PageTab[] = [
  {
    label: 'overview',
    path: 'overview',
    index: 0,
  },
  {
    partnerCannotSee: true,
    label: 'partner.plural',
    path: 'partners',
    index: 1,
  },
  {
    label: 'company.plural',
    path: 'companies',
    index: 2,
  },
  // {
  //   label: 'user.plural',
  //   path: 'users',
  //   index: 3
  // },
  {
    label: 'calls',
    path: 'calls',
    index: 4,
  },
  // {
  //   partnerCannotSee: true,
  //   label: 'meetings',
  //   path: 'meetings',
  //   index: 5
  // },
];

export const ClustersNavTabs: PageTab[] = [
  {
    label: 'media',
    path: 'media',
    index: 0
  },
  {
    label: 'collaboration',
    path: 'collaboration',
    index: 1
  },
  {
    label: 'recording',
    path: 'recording',
    index: 2
  },
  {
    label: 'certificates',
    path: 'certificates',
    index: 3
  }
];

export const PbxNavTabs: PageTab[] = [
  {
    label: 'config',
    path: 'pabx/skyvoice/config',
    index: 0,
  },
  {
    label: 'extension',
    path: 'pabx/skyvoice/extension-list',
    index: 1,
  },
  {
    label: 'providers',
    path: 'pabx/skyvoice/providers',
    index: 2,
  },
];
