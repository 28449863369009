export type ProcessStatus = 'loading'| 'done';
export interface TranscriptionResponse{
    processStatus: string
}
export interface AiService{
    uuid?: string;
    userEmail?: string;
    fileName: string;
    processStatus: ProcessStatus;
    transcriptionSummary?: string;
    loadingValue?: number;
}

export interface AiServiceFilterParams {
    userEmail: string;
    page?: number;
    limit?: number;
    leucotronService: string;
  }
export interface AiKeyCreate {
    email: string;
    key: string;
}