import { Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';
import { formParams, getLCSEventPath } from '@shared/utils';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { LcsEvent, LcsEventsFilter } from '@shared/models';
import { LcsEventTypes } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class LcsEventsService {
  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private authService: AuthService,
  ) { }

  /**
  * Create a new event
  * @param {string} type Event type
  * @param {string} description Event description
  * @param {any} value Event value - JSON object
  */
  public create(type: LcsEventTypes, description?: string, companyId?: string, value: any = {}): Promise<void> {
    // Get the path to the event creation endpoint
    const url = getLCSEventPath('lcs/events/create');

    // Send the request to the endpoint
    return lastValueFrom(this.http.post<any>(url, this.baseLcsEvent(type, description, companyId, value)));
  }

  /**
   * Create the base event object
   * @param {string} type Event type
   * @param {string} description Event description
   * @param {any} value Event value - JSON object
   * @returns {LcsEvent} Event object
   */
  public baseLcsEvent(type: LcsEventTypes, description?: string, companyId?: string, value: any = {}): LcsEvent {
    return {
      companyId: companyId ?? '',
      userId: this.authService.uid,
      userEmail: this.authService.getEmail(),
      device: this.utilsService.getBrowserName(),
      description: description ?? '',
      type,
      value: {
        ...value ?? {},
        platform: 'LCS',
      }
    };
  }

  /**
   * Get events
   * @param {LcsEventsFilter} options Events filter
   * @returns {Promise<{ events: LcsEvent[], count: number }>} List of events
   */
  public async list(options: LcsEventsFilter): Promise<{ events: LcsEvent[], count: number }> {
    // Get the path to the event list endpoint
    const url = getLCSEventPath('lcs/events/list');

    // Send the request to the endpoint
    return lastValueFrom(this.http.get<any>(url, {
      params: formParams(options),
    }));
  }
}
