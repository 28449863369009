import { Injectable } from '@angular/core';
import { Database, onValue, ref } from '@angular/fire/database';
import { ReleaseDate } from '@shared/enums';
import { ReleaseDateData } from '@shared/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionsService {
  public releaseDates$ = new BehaviorSubject<Map<ReleaseDate, ReleaseDateData>>(new Map());

  constructor(private database: Database) { }

  /**
   * Fetch Release Dates
   */
  public fetchReleaseDates(): void {
    // Ref
    const releaseDateRef = ref(this.database, 'releaseDate');

    // Get inicial values
    onValue(releaseDateRef, (snapshot) => {
      // Map to store values
      const releaseDates = new Map<ReleaseDate, ReleaseDateData>();

      // Loop through snapshot
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key as ReleaseDate;
        const childData: ReleaseDateData = childSnapshot.val();
        releaseDates.set(childKey, childData);
      });

      // Emit values
      this.releaseDates$.next(releaseDates);
    });
  }
}
