import { Observable } from 'rxjs';
import { User } from '.';

export interface AdvancedFilter {
  initialDate?: string;
  finalDate?: string;
  extension?: string;
  protocol?: string;
  type?: string;
  status?: string;
}

export interface simultCallsParams {
  limit?: number;
  initialDate?: string;
  finalDate?: string;
  companyId?: string | string[];
  clusterId?: string;
}


export interface SimultCalls {
  total: number;
  quantity: number;
  clusterId: string;
  companyId: string;
  date: Date;
}

export interface CdrFilterParams {
  page?: number;
  limit?: number;
  tenantId?: string | string[];
  initialDate?: string;
  finalDate?: string;
  extension?: string;
  protocol?: string;
  type?: string;
  status?: string | string[];
}

export interface CallsSummary {
  count?: CallsCountSummary;
  sum?: CallsSumSummary;
  context: any;
}

export interface CallsCountSummary {
  received?: number,
  outgoing?: number,
  missed?: number
}

export interface CallsSumSummary {
  storage?: string;
  totalDuration?: string;
}


export interface Cdr {
  id: number;
  uuid: string;
  calldate: string;
  uuidsrc: string;
  uuiddst: string;
  clusterId: string;
  tenantid: string;
  src: string;
  dst: string;
  start: any;
  answer: any;
  end: any;
  linkedid: string;
  uniqueid: string;
  duration: string;
  dcontext: string;
  billsec: string;
  recording: string[];
  size: string;
  channelstats: any;
  type: string;
  disposition: string;
  user?: Observable<User>;
}
