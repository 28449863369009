import { environment } from "@environments/environment";

/**
 * Form the cluster path
 * @param {string} id Cluster id
 * @returns {string} path
 */
export const getClusterPath = (id?: string): string => {
  return `voice_servers${id ? `/${id}` : ''}`
}

/**
 * Form the equipment path
 */
export const getEquipmentPath = (id?: string): string => {
  return `devices${id ? `/${id}` : ''}`
}

/**
 * Form the cdr path
 * @returns the cdr path
 */
export const getCDRPath = () => {
  return `${environment.baseURL}/cdr`;
}

/**
 * Form the Multi Talk User path
 * @param {number} multiTalkCompanyId Multi talk company id
 * @param {number} multiTalkUserId Multi talk user id
 * @returns {string} Path
 */
export const getMultiTalkUserPath = (multiTalkCompanyId: number, multiTalkUserId?: number): string => {
  return `${environment.multiTalkBaseUrl}/company/${multiTalkCompanyId}/extension${multiTalkUserId ? `/${multiTalkUserId}` : ''}`;
}

/**
 * Form the Multi Talk Dialplan path
 * @param {number} multiTalkCompanyId Multi talk company id
 * @param {number} multiTalkUserId Multi talk user id
 * @returns {string} Path
 */
export const getMultiTalkDialPlanPath = (multiTalkCompanyId: number, multiTalkUserId?: number): string => {
  return `${environment.multiTalkBaseUrl}/company/${multiTalkCompanyId}/dialplan${multiTalkUserId ? `/${multiTalkUserId}` : ''}`;
}

/**
 * Form the Multi Talk Company path
 * @param {number} multiTalkCompanyId Multi talk company id
 * @returns {string} Path
 */
export const getMultiTalkCompanyPath = (multiTalkCompanyId?: number): string => {
  return `${environment.multiTalkBaseUrl}/company${multiTalkCompanyId ? `/${multiTalkCompanyId}` : ''}`;
}

/**
 * Form the company path
 * @param {string} id Company id
 * @returns {string} path
 */
export const getCompanyPath = (id?: string): string => {
  return `companies${id ? `/${id}` : ''}`
}

/**
 * Create group path
 * @param {string} companyId Company ID
 * @param {string} id Grupo ID
 * @returns {string} path to the group collection
 */
export const getGroupsPath = (companyId: string, id?: string): string => {
  return `companies/${companyId}/groups/${id ?? ''}`
}

/**
 * Create integration path
 * @param {string} companyId Company ID
 * @param {string} id Integration ID
 * @returns {string} path to the integration collection
 */
export const getIntegrationPath = (companyId: string, id?: string): string => {
  return `companies/${companyId}/integrations/${id ?? ''}`
}

/**
 * Create partner path
 * @param {string} id Partner ID
 * @returns {string} path to the partner collection
 */
export const getPartnerPath = (id?: string): string => {
  return `partners/${id ?? ''}`;
}

/**
 * Create lcs user path
 * @param {string} id User ID
 * @returns {string} path to the lcs user collection
 */
export const getLcsUserPath = (id?: string): string => {
  return `lcs_users/${id ?? ''}`;
}

/**
 * Create pbx path
 * @param {string} companyId Company ID
 * @param {string} id Pbx ID
 * @returns {string} path to the pbx collection
 */
export const getPBXPath = (companyId: string, id?: string): string => {
  return `companies/${companyId}/pbx/${id ?? ''}`
}

/**
 * Create user path
 * @param {string} companyId Company ID
 * @param {string} id user ID
 * @returns {string} path to the user collection
 */
export const getUserPath = (companyId: string, id?: string): string => {
  return `companies/${companyId}/users/${id ?? ''}`
}

/**
 * Create sector path
 * @param {string} companyId Company ID
 * @param {string} id Sector ID
 * @returns {string} path to the sector collection
 */
export const getSectorPath = (companyId: string, id?: string): string => {
  return `companies/${companyId}/sectors/${id ?? ''}`
}

/**
 * Create user path
 * @param {string} companyId Company ID
 * @param {string} id User ID
 * @returns {string} path to the user collection
 */
export const getUserFirestorePath = (companyId: string, id?: string): string => {
  return `companies/${companyId}/users/${id ?? ''}`
}

/**
 * Create user path
 * @param {string} companyId Company ID
 * @param {string} id user ID
 * @returns {string} path to the user collection
 */
export const getUserFunctionsPath = (companyId: string, id?: string): string => {
  return `${environment.baseURL}/users/tenants/${companyId}/users${id ? `/${id}` : ''}`;
}

/**
 * Create lcs event path
 */
export const getLCSEventPath = (endpoint: string): string => {
  return `${environment.baseURL}/lcsEvents${endpoint ? `/${endpoint}` : ''}`;
}

/**
 * Create user path
 * @param {string} companyId Company ID
 * @param {string} id user ID
 * @returns {string} path to the user collection
 */
export const getPartnerFunctionsPath = (id?: string): string => {
  return `${environment.baseURL}/partner${id ? `/${id}` : ''}`;
}


/**
 * Form the extensions path
 */
export const getExtensionPath = (equipId: string, extId?: string): string => {
  return `devices/${equipId}/extensions${extId ? `/${extId}` : ''}`
}

/**
 * Form the extensions path
 */
export const getExtensionPathByCompanyId = (companyId: string, extId?: string): string => {
  return `companies/${companyId}/extensions${extId ? `/${extId}` : ''}`
}

/**
 * Form the extensions path
 */
export const getProviderPath = (equipId: string, provId?: string): string => {
  return `devices/${equipId}/providers${provId ? `/${provId}` : ''}`
}
