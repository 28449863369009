import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatNumber',
  pure: false
})
export class FormatNumberPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
  ) { }

  transform(
    value: number | string,
    unitType: 'size' | 'time' | 'numeric' = 'numeric'
  ): string {
    // Format number
    let result = this.format(+value, unitType);

    // Return the result
    return result
  }


  /**
   * Format the number by lang
   * @param {number} value
   * @param {UNIT_TYPE} unitType Measurement unit
   * @returns Formatted number
   */
  private format(value: number, unitType: 'size' | 'numeric' | 'time'): string {
    switch (unitType) {
      case 'time':
        return this.formatTime(value);

      case 'size':
        return this.formatNumberAndSize(value, 'size');

      default:
        return this.formatNumberAndSize(value, 'numeric');
    }
  }

  /**
   * Format the number by lang
   * @param {number} value
   * @returns Formatted number
   */
  private formatTime(value: number): string {
    if (!value) return '0s';

    const d = Math.floor(value / (3600 * 24));
    const h = Math.floor(value % (3600 * 24) / 3600);
    const m = Math.floor(value % 3600 / 60);
    const s = Math.floor(value % 60);

    const dDisplay = d > 0 ? d + (d == 1 ? "d " : "d ") : "";
    const hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";

    const result = dDisplay + hDisplay + mDisplay + sDisplay;

    return result.trim();
  }

  /**
   * Format the number by lang
   * @param {number} value
   * @param {'size' | 'numeric'} unitType Measurement unit
   * @returns Formatted number
   */
  private formatNumberAndSize(value: number, unitType: 'size' | 'numeric'): string {
    // Measurement unit
    const quadrillion = 10 ** 15;
    const trillion = 10 ** 12;
    const billion = 10 ** 9;
    const million = 10 ** 6;
    const thousand = 10 ** 3;

    // Quadrillion
    if (value / quadrillion >= 1) {
      const calc = value / quadrillion;
      if (unitType == 'size') return this.intlNumber(calc, 'PB');
      const unit = Math.round(calc) == 1 ? 'quadrillion.singular' : 'quadrillion.plural';
      return this.intlNumber(calc, unit);
    }

    // Trillion
    if (value / trillion >= 1) {
      const calc = value / trillion;
      if (unitType == 'size') return this.intlNumber(calc, 'TB');
      const unit = Math.round(calc) == 1 ? 'trillion.singular' : 'trillion.plural';
      return this.intlNumber(calc, unit);
    }

    // Billion
    if (value / billion >= 1) {
      const calc = value / billion;
      if (unitType == 'size') return this.intlNumber(calc, 'GB');
      const unit = Math.round(calc) == 1 ? 'billion.singular' : 'billion.plural';
      return this.intlNumber(calc, unit);
    }

    // Million
    if (value / million >= 1) {
      const calc = value / million;
      if (unitType == 'size') return this.intlNumber(calc, 'MB');
      const unit = Math.round(calc) == 1 ? 'million.singular' : 'million.plural';
      return this.intlNumber(calc, unit);
    }

    // Thousand
    if (value / thousand >= 1) {
      const calc = value / thousand;
      if (unitType == 'size') return this.intlNumber(calc, 'kB');
      return this.intlNumber(calc, 'thousand');
    }

    return `${value}${unitType === 'size' ? ' bytes' : ''}`
  }

  /**
   * Format number by location
   * @param {number} value Number that will be formatted
   * @param {string} unit Measurement unit
   * @returns {string} Number formatted by location
   */
  private intlNumber(value: number, unit: string): string {
    const locateOptions = { maximumFractionDigits: 1 };
    const newValue = value.toLocaleString(this.translate.currentLang, locateOptions)
    return `${newValue} ${this.translate.instant(unit)}`
  }
}
