import { COMPANY_ACTIONS, EXTENSION_ACTIONS } from "@shared/enums";

export interface MultiTalkAuth {
  usuario: string,
  token: string,
}

export interface MultiTalkResult<T> {
  http_response_code: string,
  qtd_total_resultados?: string,
  qtd_resultados_retornados?: string,
  mensagem?: string,
  ramal_id?: string,
  dados?: T[],
}

export interface MultiTalkCompanyCreateResult {
  multiTalkCompanyId: number;
}

export interface MultiTalkUserCreateResult {
  multiTalkUserId: number;
  multiTalkAuthorizationUser: string;
}

export interface MultiTalkUserUpdateResult extends MultiTalkUserCreateResult { }

export interface MultiTalkBase {
  autenticacao: MultiTalkAuth,
  acao: COMPANY_ACTIONS | EXTENSION_ACTIONS,
}

export interface MultiTalkCreate extends MultiTalkBase {
  dados: MultiTalkCompany | MultiTalkUser,
}

export interface MultiTalkDelete extends MultiTalkBase {
  cliente_id?: number,
  ramal_id?: number,
}

export interface MultiTalkList extends MultiTalkBase {
  cliente_id?: number,
  ramal_id?: number,
  nome?: string,
  pos_registro_inicial?: number,
}

export interface MultiTalkCompany {
  cliente_id?: number,
  login?: string,
  senha?: string,
  tipo?: (1 | 2 | number),
  nome?: string,
  email_cliente?: string,
  max_chamadas_simultaneas?: number,
  faixa_min?: string,
  faixa_max?: string,
  prefixo?: string,
  habilita_prefixo_sainte?: (0 | 1 | number),
  prefixo_sainte?: string,
  qtd_ramais_max_pabx?: string,
  qtd_ramais_max_pa?: string,
  razao_social?: string,
  cnpj?: string,
  end?: string,
  compl?: string,
  cep?: string,
  bairro?: string,
  cidade?: string,
  estado?: string,
  resp?: string,
  tel?: string,
  cel?: string,
  ramal_resp?: string,
  salas_conf_num_max?: string,
  espaco_disco?: string,
  acao_limite_espaco?: string,
  usuario_padrao_id?: string,
  cliente_bloqueado?: (0 | 1),
}

export interface MultiTalkUser {
  ramal_id?: number,
  cliente_id?: number,
  tipo?: (1 | 2 | number),
  nome?: string,
  senha?: string,
  usuario_autenticacao?: string,
  numero?: number,
  senha_sip?: string,
  senha_web?: string,
  caller_id_externo?: string,
  grupo_ramais?: string,
  centro_custo?: string,
  plano_discagem_id?: string,
  grupo_musica_espera?: string,
  puxar_chamadas?: string,
  habilitar_timers?: string,
  habilitar_blf?: string,
  escutar_chamadas?: string,
  gravar_chamadas?: string,
  bloquear_ramal?: string,
  codigo_area?: string,
  habilitar_dupla_autenticacao?: (0 | 1),
  dupla_autenticacao_ip_permitido?: string,
  dupla_autenticacao_mascara?: string,
  habilitar_caixa_postal?: string,
  caixa_postal_email?: string,
  encaminhar_todas_chamadas?: MultiTalkForwarding,
  encaminhar_offline_sem_atendimento?: MultiTalkForwarding,
  encaminhar_ocupado_indisponivel?: MultiTalkForwarding,
}

export interface MultiTalkForwarding {
  encaminhamento_tipo: (0 | 1 | 2 | 3 | 4 | 5),
  encaminhamento_destino: string,
  encaminhamento_destinos: any[],
}


export interface Dialplan {
  plano_discagem_id: string,
  cliente_id: string,
  nome: string
}