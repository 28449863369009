import { PBX, LoginOptions } from '@shared/models';

export interface Domain {
  clusterId: string;
  domainId: string;
  name: string;
  partner: string;
  imgUrl?: string;
  media: PBX[];
  active: boolean;
  needToSync?: boolean;
  loginOptions?: LoginOptions[];
  createdAt?: Date;
  updatedAt?: Date;
}
