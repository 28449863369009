import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { CompanyService, GroupsService } from '@core/services';
import { Company } from '@shared/models';
import { first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyResolver implements Resolve<Company> {
  private company: Company;

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private groupService: GroupsService,
  ) { }

  public async resolve(route: ActivatedRouteSnapshot): Promise<Company> {
    // Get company ID
    const companyId = route.params['id'];

    // Get company data
    await this.getCompany(companyId);

    // Return the result
    return this.company
      ? await this.validCompany(companyId)
      : this.invalidCompany();
  }

  /**
   * Get company data
   * @param {string} id Company ID | Tenant ID
   */
  private async getCompany(id: string) {
    this.company = await this.companyService.getCompanyById(id);
  }

  /**
   * Get all sub-collections data
   * @param {string} id Company ID | Tenant ID
   */
  private async getSubCollections(id: string) {
    await lastValueFrom(this.groupService.fetchAll(id).pipe(first()))
  }

  /**
   * Company not found
   * @returns Null value
   */
  private invalidCompany() {
    this.router.navigate(['companies']);
    return null;
  }

  /**
   * Company exist
   * @param {string} id Company ID | Tenant ID
   * @returns {Company} Company data
   */
  private async validCompany(id: string): Promise<Company> {
    await this.getSubCollections(id)
    return this.company;
  }
}
