import { Injectable } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  collection,
  collectionData,
  addDoc,
  doc,
  deleteDoc,
  updateDoc
} from '@angular/fire/firestore';
import { Company, Integration } from '@shared/models';
import { getIntegrationPath } from '@shared/utils/create-path';
import { first, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(private firestore: Firestore) { }

  /**
   * Get integrations
   * @param {string} id tenant ID
   * @returns All integrations that belong to that company
   */
  public fetchAll(id: string): Observable<Integration[]> {
    const col = <CollectionReference<Integration>>(
      collection(this.firestore, getIntegrationPath(id))
    );

    return collectionData<Integration>(col, { idField: 'id' }).pipe();
  }

  /**
   * Create a new integration
   * @param {Company} company The company that is the owner of the integration
   * @param {Integration} integration Obj that contains the integration data
   */
  public async create(company: Company, integration: Integration): Promise<string> {
    const colRef = collection(this.firestore, getIntegrationPath(company.id));
    const newDoc = await addDoc(colRef, {
      companyId: company.id,
      name: integration.name,
      icon: integration.icon,
      type: integration.type,
      enabled: integration.enabled,
      domain: integration.domain,
      groups: integration.groups ?? [],
      openInWindow: integration.openInWindow ?? true,
    });

    return newDoc.id;
  }

  /**
   * Delete a integration
   * @param {Company} company The company that is the owner of the integration
   * @param {Integration} integration The integration that will be removed
   */
  public async deleteIntegration(company: Company, integration: Integration): Promise<void> {
    const docRef = doc(this.firestore, getIntegrationPath(company.id, integration.id));
    await deleteDoc(docRef);
  }

  /**
   * Update a integration
   * @param {Company} company The company that is the owner of the integration
   * @param {Integration} integration New Data
   * @returns The new integration
   */
  public update(company: Company, integration: Integration): Promise<void> {
    const docRef = doc(this.firestore, getIntegrationPath(company.id, integration.id));
    return updateDoc(docRef, {
      companyId: company.id,
      name: integration.name,
      icon: integration.icon,
      type: integration.type,
      enabled: integration.enabled,
      domain: integration.domain,
      groups: integration.groups ?? [],
      openInWindow: integration.openInWindow ?? true,
    });
  }
}
